<template>
  <div class="tag-select">
    <v-select
      v-if="availableTags && availableTags.length"
      v-model="selectedTags"
      multiple
      taggable
      :placeholder="$t('alert.Labels')"
      :options="availableTags"
      :reduce="(option) => option"
      label="name"
      @input="tagFormatter"
      menu-props="auto"
      @search="filter"
    >
      <template #option="{ name, isNew }">
        <p style="margin: 0">
          {{ name }}<span v-if="!isNew">{{ $t("groups.NewLabel") }}</span>
        </p>
      </template>
    </v-select>
    <v-select
      v-else
      v-model="selectedTags"
      multiple
      ref="mySelect"
      taggable
      :placeholder="$t('alert.Labels')"
      :options="availableTags"
      :reduce="(option) => option"
      label="name"
      @input="tagFormatter"
      menu-props="auto"
      ><template #no-options>
        {{ $t("groups.TagMultSelectPlaceHolder") }}
      </template>
    </v-select>
  </div>
</template>
<script>
import vSelect from "vue-select";
import UtilityService from "@/libs/api/utility-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    vSelect
  },

  data() {
    return {
      selectedTags: [],
      availableTags: [],
      isNew: false
    };
  },
  mounted() {
    this.getAllGroups();
    const me = this;
    setTimeout(() => {
      const selectInput = document.querySelector(".tag-select .vs__search");
      selectInput.focus();
    }, 300);
  },
  props: [
    "value",
    "isList",
    "event",
    "entityType",
    "isEditableTags",
    "listApi"
  ],
  watch: {
    selectedTags(old, newVal) {
      this.$emit("input", this.selectedTags);
    },
    value(newVal, old) {
      this.getSelectedTags(newVal);
    }
  },
  methods: {
    filter(tag) {
      this.isNew = false;
      if (tag) {
        this.availableTags &&
          this.availableTags.map((t) => {
            t.isNew = t.name.toLowerCase().includes(tag.toLowerCase());
          });
      } else {
        this.availableTags.map((t) => {
          t.isNew = true;
        });
      }
    },
    async tagFormatter(tag) {
      if (tag.every((item) => typeof item === "string")) {
        tag = tag.map((e) => {
          return { name: e };
        });
      }
      this.selectedTags = tag.filter((t) => t.name.trim() !== "");
      let lastElement = tag && tag[tag.length - 1];

      if (lastElement) {
        if (!this.availableTags.includes(lastElement)) {
          for (let i = 0; i < this.availableTags.length; i++) {
            if (
              this.availableTags[i].name.toLowerCase() ===
              lastElement.name.toLowerCase()
            ) {
              this.selectedTags.pop();
              if (
                !this.selectedTags.some(
                  (tag) => tag.name === this.availableTags[i].name
                )
              ) {
                this.selectedTags.push(this.availableTags[i]);
              }
            }
          }
        }
      }
      this.isEditable = false;

      if (this.isList) {
        let oldTag = this.value;
        let newTag = tag;
        const comparisonTag = this.compareTags(oldTag, newTag);
        if (comparisonTag.addedTags.length == 1) {
          let requestData = {
            entity_id: this.event.id,
            entity_type: this.entityType,
            name: comparisonTag.addedTags[0].name
          };
          if (comparisonTag.addedTags[0].id) {
            requestData.label_id = comparisonTag.addedTags[0].id;
          }

          let response = await new UtilityService().tagEntity(requestData);
          if (response && response.data) {
            await this.getAllGroups();
            const index = this.selectedTags.findIndex(
              (s) => s.name == comparisonTag.addedTags[0].name
            );
            if (index > -1) {
              this.selectedTags[index].id = response.data.id;
            }
            const me = this;
            const selectInput1 = document.querySelector(
              ".tag-select .vs__search"
            );
            selectInput1.focus();
          } else if (response && response.error && response.error.message) {
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "InfoIcon",
                variant: "danger"
              }
            });
          }
          //await this.listApi();
        }
        if (comparisonTag.removedTags.length == 1) {
          if (!comparisonTag.removedTags[0].id) {
            return;
          }
          let response = await new UtilityService().unTagEntity({
            label_id: comparisonTag.removedTags[0].id,
            entity_id: this.event.id,
            entity_type: this.entityType
          });
          if (response && response.data) {
            this.show = false;
            await this.getAllGroups();
            //this.isEditableTags();add save code
            const me = this;
            const selectInput2 = document.querySelector(
              ".tag-select .vs__search"
            );
            selectInput2.focus();
          } else if (response && response.error && response.error.message) {
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "InfoIcon",
                variant: "danger"
              }
            });
          }
        }
      }
    },
    compareTags(oldTag, newTag) {
      const addedTags = [];
      const removedTags = [];

      // Check for added items
      newTag.forEach((newItem) => {
        const foundItem = oldTag.find(
          (oldItem) =>
            oldItem.id === newItem.id && oldItem.name === newItem.name
        );
        if (!foundItem) {
          addedTags.push(newItem);
        }
      });

      // Check for removed items
      oldTag.forEach((oldItem) => {
        const foundItem = newTag.find(
          (newItem) =>
            newItem.id === oldItem.id && newItem.name === oldItem.name
        );
        if (!foundItem) {
          removedTags.push(oldItem);
        }
      });

      return { addedTags, removedTags };
    },
    getSelectedTags(value) {
      if (value && value.length) {
        const isValidateLabels = value.every(
          (item) =>
            typeof item === "object" &&
            item !== null &&
            Object.keys(item).length
        );

        if (isValidateLabels) {
          this.selectedTags = value || [];
        }
      } else {
        if (this.value == []) {
          this.selectedTags = [];
        }
      }
    },

    async getAllGroups() {
      try {
        this.getSelectedTags(this.value);

        let response = await new UtilityService().getAllGroups({});

        if (response && response.data) {
          this.availableTags = response.data.list || [];
          this.availableTags.map((t) => {
            t.isNew = true;
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
.tag-select {
  ul.vs__dropdown-menu {
    max-height: 160px;
  }
  &.disabled {
    border-radius: 6px;
    background-color: var(--white3) !important;
  }
}
</style>

<template>
  <b-media
    no-body
    v-if="
      !(latitude === null || latitude === undefined) ||
      !(longitude === null || longitude === undefined)
    "
  >
    <b-media-aside class="mr-75 marginLeftImg">
      <location-icon />
    </b-media-aside>
    <div  class="main-skeletion-div">
      <b-skeleton
        v-for="(width, i) in [95, 80, 85]"
        v-bind:key="i"
        v-show="addressLoading"
        animation="wave"
        :width="width + '%'"
        class="skeletion-css"
      ></b-skeleton>

      <h6 v-show="!addressLoading" class="align-self-center my-auto">
        <a :href="locationUrl" target="_blank"  class="locationUrlcolor">
          {{ location }}
        </a>
      </h6>
    </div>
  </b-media>
</template>

<script>
import {
  BMedia,
  BMediaBody,
  BImg,
  BMediaAside,
  BAvatar,
  BOverlay,
  BSkeleton,
  BCard
} from "bootstrap-vue";
import GeoService from "@/libs/api/geo-service";
import LocationIcon from "@/assets/images/icons/unit-icons/location.svg";
export default {
  name: "gps",
  components: {
    BMedia,
    BMediaBody,
    BImg,
    BMediaAside,
    BAvatar,
    BOverlay,
    BSkeleton,
    BCard,
    LocationIcon
  },
  props: ["latitude", "longitude", "address"],
  data() {
    return {
      location: "",
      addressLoading: true,
      locationUrl: ""
    };
  },
  mounted() {
    this.getLocation();
    this.getLocationUrl();
  },
  watch: {
    latitude() {
      this.getLocationDelayed();
    },
    longitude() {
      this.getLocationDelayed();
    }
  },
  methods: {
    async getLocationDelayed() {
      clearTimeout(this.getLocationTO);
      this.getLocationTO = setTimeout(() => {
        this.getLocation();
      }, 2000);
    },
    async getLocation() {
      if (this.address) {
        this.addressLoading = true;
        this.location = this.address.display_name
          ? this.address.display_name
          : "";
        this.addressLoading = false;
        return;
      }
      try {
        this.addressLoading = true;
        if (this.address && this.address.display_name) {
          this.location = this.address.display_name;
          this.addressLoading = false;
          return;
        }
        let response = await new GeoService().findAddressFromCordinates({
          lat: this.latitude ? this.latitude.toString() : this.latitude,
          lng: this.longitude ? this.longitude.toString() : this.longitude
        });
        if (response && response.data) {
          this.addressLoading = false;
          this.location = response.data.display_name
            ? response.data.display_name
            : "";
        } else if (response && response.error && response.error.message) {
          this.addressLoading = false;
          this.location = response.error.message;
        }
      } catch (err) {
        this.addressLoading = false;
        this.location = err.message;
      }
    },
    getLocationUrl() {
      this.locationUrl = `http://maps.google.com/maps?q=loc:${this.latitude},${this.longitude}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.media {
  align-items: center !important;
  padding-top: 10px;
}
.skeletion-css{
   margin-bottom: 4px;
    height :7px;
}
.locationUrlcolor{
  color: #7367f0;
}
.main-skeletion-div{
width: 100%;
}
</style>

<template>
  <div>
    <div class="modal-info-track">
      <div v-for="(sensor, key) in messages" :key="key">
        <div v-if="MAPPINGS[sensor.type]" class="modal-info">
          <keep-alive>
            <component
              :is="MAPPINGS[sensor.type]"
              v-bind="{
                ...sensor.data,
                name: sensor.name,
                options: options,
                unitObj: unitObj,
                deviceObj: deviceObj
              }"
            ></component>
          </keep-alive>
        </div>
      </div>
    </div>
    <div class="report-time" v-if="message_time">
      <h6>{{ reportTime }}</h6>
    </div>
  </div>
</template>

<script>
import { BCard, BCardBody, BIcon } from "bootstrap-vue";
import speed from "./speed.vue";
import vehicleStatus from "./vehicleStatus.vue";
import fuel from "./fuel.vue";
import odometer from "./odometer.vue";
import gsmSignal from "./gsmSignal.vue";
import gps from "./gps.vue";
import videoPlayer from "./videoPlayer.vue";
import moment from "moment";
const COMPONENT_ORDER = [
  "GPS",
  "VIDEO_STREAM",
  "VEHICLE_STATUS",
  "SPEED",
  "FUEL",
  "ODOMETER",
  "GSM"
].reverse();
const SENSOR_COMPONENTS = {
  speed,
  vehicleStatus,
  fuel,
  odometer,
  gsmSignal,
  gps,
  videoPlayer
};

export default {
  name: "sensorInfo",
  components: { BIcon, BCard, BCardBody, ...SENSOR_COMPONENTS },
  // props: ["messages", "message_time", "options"],
  props: {
    messages: {
      type: Array,
      default: () => {
        return [];
      }
    },
    message_time: {
      type: String
    },
    options: {
      type: Object,
      default: () => {
        return { isLiveMode: true };
      }
    },
    unitObj: {
      required: true,
      type: Object
    },
    deviceObj: {
      required: true,
      type: Object
    }
  },
  methods: {},

  data() {
    return {
      reportTime: "",
      MAPPINGS: {
        SPEED: speed,
        VEHICLE_STATUS: vehicleStatus,
        FUEL: fuel,
        ODOMETER: odometer,
        GSM: gsmSignal,
        GPS: gps,
        VIDEO_STREAM: videoPlayer
      }
    };
  },

  beforeDestroy() {
    clearInterval(this.timeInterval);
  },
  created() {
    this.updateReportTime();
    this.sortMessageComponents();
    this.timeInterval = setInterval(this.updateReportTime, 15 * 1000);
  },
  watch: {
    message_time: function (newVal, oldVal) {
      this.updateReportTime();
    }
  },
  computed: {},
  methods: {
    updateReportTime() {
      const duration = moment.duration(moment().diff(this.message_time));
      if (duration.asMinutes() < 59) {
        this.reportTime = moment(this.message_time).fromNow();
      } else if (duration.asMinutes() < 1440) {
        this.reportTime = moment(this.message_time).calendar();
      } else {
        this.reportTime = moment(this.message_time).local();
      }
    },
    sortMessageComponents() {
      this.messages = this.messages.sort(function (a, b) {
        return (
          COMPONENT_ORDER.indexOf(b.type) - COMPONENT_ORDER.indexOf(a.type)
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-info-track {
  text-align: left;
}
.media {
  align-items: center !important;
  padding-top: 10px;
}
.report-time {
  text-align: center;
  width: 100%;
  padding: 13px;
  margin-top: 10px;
  padding-bottom: 0;
  h6 {
    font-style: italic;
    font-size: 10px;
    text-transform: initial;
  }
}
</style>

<template>
  <div class="operator-table-width">
    <b-card no-body class="mb-0">
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="10"
          :columns="5"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        v-if="!show"
        class="position-relative sim-icon-mb Operator-table child-1-30-percent"
        responsive
        show-empty
        align-v="end"
        :items="items"
        sticky-header
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <template #cell(operator_name)="row">
          <b-media class="align-items-center">
            <template #aside>
              <b-avatar
                size="50"
                src="RA"
                :text="row.item.avtar_txt"
                :variant="row.item.variant"
              />
            </template>
            <h6 class="mb-0 d-flex mobile-no-wrap">
              {{ row.item.name }}
            </h6>
          </b-media>
        </template>

        <template #cell(operator_code)="row">
          <h6 class="mobile-no-wrap mb-0">
            {{ `${row.item.code ? `${row.item.code}` : " "}` }}
          </h6></template
        >
        <template #cell(linked_user)="row">
          <h6>
            {{
              `${row.item.first_name ? `${row.item.first_name}` : " "}` +
              " " +
              `${row.item.last_name ? `${row.item.last_name}` : " "}`
            }}
          </h6>
        </template>
        <template #cell(linked_unit)="row">
          <ul
            v-if="row.item.units.length"
            class="device-list text-left p-0 mb-0"
          >
            <li
              style="list-style-type: none"
              :key="unit.id"
              v-for="unit in row.item.units"
            >
              <b-link
                target="_blank"
                :to="{
                  name: 'view-unit',
                  params: {
                    id: unit.id
                  }
                }"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.READ,
                    subject: constants.PERMISSIONS_MODEL.UNITS
                  })
                "
              >
                <b class="mobile-no-wrap">{{ unit.name }}</b>
              </b-link>
              <!-- <b-link  class="text-col-unit">
                {{ unit.name }}
              </> -->
              <br />
              <span v-if="unit.start_date && unit.end_date">
                <h6>
                  ({{ formattedDates(unit.start_date) }} To
                  {{ formattedDates(unit.end_date) }})
                </h6>
              </span>
              <span v-else>
                <h6>
                  {{
                    $t("operators.UnitAllocatedSince") +
                    " " +
                    formattedDates(unit.start_date)
                  }}
                </h6>
              </span>
            </li>
          </ul>
        </template>

        <template #cell(labels)="row">
          <div class="parent" id="parent">
            <DynamicBadge
              :isList="true"
              :entityType="'OPERATOR'"
              :tagsData="row.item.labels"
              :showTextInBadge="Number(7)"
              :event="row.item"
              :maxTagShow="Number(defaultBadgeShow)"
              :listApi="debounceGetOperators"
            ></DynamicBadge>
          </div>
        </template>
        <template #cell(ACTIONS)="row">
          <div class="text-left track_tool">
            <span :id="row.item.id">
              <b-link
                :to="{
                  name: 'view-operator',
                  params: { id: row.item.id, isEnabled: true }
                }"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.READ,
                    subject: constants.PERMISSIONS_MODEL.OPERATORS
                  })
                "
              >
                <feather-icon
                  v-b-tooltip.hover.v-primary
                  :title="$t('tooTip.view')"
                  icon="EyeIcon"
                  class="curserPointer text-primary action-icon"
                  size="18"
                />
              </b-link>
            </span>
            <span :id="row.item.up_tool">
              <b-link
                :to="{
                  name: 'edit-operator',
                  params: { id: row.item.id, isEnabled: false }
                }"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.UPDATE,
                    subject: constants.PERMISSIONS_MODEL.OPERATORS
                  })
                "
              >
                <feather-icon
                  v-b-tooltip.hover.v-primary
                  :title="$t('tooTip.update')"
                  icon="EditIcon"
                  class="curserPointer text-primary action-icon"
                  size="18"
                />
              </b-link>
            </span>
            <span>
              <feather-icon
                v-b-tooltip.hover.v-primary
                :title="$t('tooTip.delete')"
                icon="Trash2Icon"
                class="text-danger action-icon curserPointer"
                size="18"
                v-b-modal.modal-sm-remove
                @click="deleteOperator(row.item)"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.DELETE,
                    subject: constants.PERMISSIONS_MODEL.OPERATORS
                  })
                "
              />
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalOperators > 0"
              v-model="currentPage"
              :total-rows="totalOperators"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="handlePageChange"
              :no-paging-nav="false"
              :active-class="activeClass"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <AreYouSureModal
      :data="removedOperatorData"
      :onClose="onClose"
      :removedUser="removeOperator"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";

import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import UnitService from "@/libs/api/unit-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import * as moment from "moment";
import constants from "@/utils/constants";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    BBadge,
    BPagination,
    AreYouSureModal,
    VBTooltip,
    BSkeletonTable,
    DynamicBadge,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      tableColumns: [
        {
          key: "operator_name",
          tdClass: "first-child",
          thClass: "first-child text-left",
          label: this.$t("operators.tableColumns.operatorName")
        },
        {
          key: "operator_code",
          tdClass: "operator-code",
          thClass: "text-left",
          label: this.$t("operators.tableColumns.operatorCode")
        },
        {
          key: "linked_user",
          tdClass: "text-left",
          thClass: "text-left",
          label: this.$t("operators.tableColumns.linked_user")
        },
        {
          key: "linked_unit",
          tdClass: "text-left",
          thClass: "text-left",
          label: this.$t("operators.tableColumns.linked_unit")
        },

        {
          key: "ACTIONS",
          tdClass: "operator-action-width",
          thClass: "text-center",
          label: this.$t("operators.tableColumns.actions")
        }
      ],
      totalOperators: 0,
      // perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      activeClass: "active",
      items: [],
      removedOperatorData: {},
      show: false,
      defaultBadgeShow: 2,
      timeoutId: null
    };
  },
  props: {
    searchFiled: Object,
    groupName: String,
    perPage: {
      type: Number,
      default: 10
    }
  },

  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }

      this.debounceGetOperators();
    });
    if (
      this.checkAbility({
        action: constants.PERMISSIONS_ACTION.READ,
        subject: constants.PERMISSIONS_MODEL.LABELS
      })
    ) {
      this.tableColumns.splice(-1, 0, {
        key: "labels",
        tdClass: "operator-group-width labels-width badge-230",
        thClass: "operator-group-width labels-width text-right badge-230"
      });
    }
    if (this.groupName) {
      this.debounceGetOperators();
    }
    window.addEventListener("resize", this.showLabelSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.showLabelSize);
  },
  watch: {
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      //this.handlePageChange(val);
      this.debounceGetOperators();
    },
    currentPage() {
      this.debounceGetOperators();
    },
    searchFiled(newVal, oldVal) {
      this.filter = newVal;
      this.debounceGetOperators();
    }
  },
  methods: {
    debounceGetOperators() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getOperators();
      }, 300); // Adjust the delay as necessary
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    showLabelSize() {
      setTimeout(() => {
        const parentElement = document.querySelector(".parent");
        if (parentElement) {
          const parentWidth = parentElement.offsetWidth;
          this.defaultBadgeShow = Math.floor(parentWidth / 71);
        }
      }, 200);
    },
    formattedDates(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    async getOperators() {
      try {
        this.show = true;
        if (!this.filter || !this.filter.value) {
          if (this.$route.query.filter) {
            this.filter = {
              field: "all",
              operator: "ilike",
              value: this.$route.query.filter
            };
          } else {
            this.filter = {};
          }
        }
        let requestData = {
          page: this.currentPage,
          page_size: parseInt(this.perPage),
          filters:
            this.filter && Object.keys(this.filter).length > 0
              ? [this.filter]
              : []
        };

        if (this.groupName) {
          requestData.label = this.groupName;
        }

        let response = await new UnitService().getOperators(requestData);
        if (response && response.data) {
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.del_tool = `del${u.id}`;
            u.up_tool = `up${u.id}`;
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.name);
            return u;
          });
          this.totalOperators =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
      this.showLabelSize();
    },
    deleteOperator(props) {
      this.status = true;
      this.removedOperatorData = { name: props.name, id: props.id };
    },

    onClose() {
      this.debounceGetOperators();
      this.$bvModal.hide("modal-sm-remove");
    },
    async removeOperator(props) {
      this.removedOperatorData = { name: "", id: "" };
      if (props.id) {
        try {
          this.onClose();
          let response = await new UnitService().removeOperator({
            id: props.id
          });

          if (response && response.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("operators.OperatorRemovedTitle"),
                text: this.$t("operators.OperatorRemovedMsg"),
                icon: "EditIcon",
                variant: "success"
              }
            });

            this.debounceGetOperators();
          } else if (response && response.error && response.error.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "EditIcon",
                variant: "error"
              }
            });
          }
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        perPage: this.perPage.toString(),
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
    }
  },
  computed: {}
};
</script>
<style lang="scss">
.alert-action-width {
  max-width: 130px;
  width: 130px;
  min-width: 130px;
}
.status-width {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
  text-align: center;
}
.badge-group {
  height: 15px;
  padding: 1px 9px 1px 9px;
  font-size: 9px;
  line-height: 11px;
}

.Operator-table {
  /*width: calc(100% + 3rem);
  margin-left: -1.5rem;
  margin-right: -1.5rem;*/
  .table {
    td,
    th {
      padding: 0.72rem 1rem;
    }
    th.first-child,
    td.first-child {
      padding-left: 2rem;
    }
    .operator-group-width {
      // width: 175px;
      min-width: 175px;
      // max-width: 175px;
      text-align: left !important;
    }
    .operator-action-width {
      width: 135px;
      min-width: 135px;
      max-width: 135px;
    }
    .operator-code {
      width: 115px;
      min-width: 115px;
      max-width: 115px;
    }
  }
}
.operator-table-width {
  .table-responsive {
    max-height: calc(100vh - 270px) !important;
    overflow-y: auto;
  }

  .b-table-sticky-header {
    max-height: calc(100vh - 270px) !important;
    overflow-y: auto;
    .table {
      width: calc(100% - 18px) !important;
    }
  }
  .b-table-sticky-header {
    @supports (position: sticky) {
      .table.b-table > thead > tr > .table-b-table-default,
      .table.b-table > tbody > tr > .table-b-table-default,
      .table.b-table > tfoot > tr > .table-b-table-default {
        top: -1px !important;
      }
    }
  }
}
</style>
<style lang="scss">
.dark-layout .b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      color: #d0d2d6 !important;
    }
  }
}
</style>

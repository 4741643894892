<template>
  <b-row>
    <b-col md="12">
      <b-form-group>
        <treeselect
          v-model="accountId"
          :multiple="false"
          :options="accountsList"
          :searchable="true"
          ref="treeselect"
          :clearable="all ? true : false"
          :clear-on-select="true"
          :placeholder="all ? placeholderName : $t('UnitTrack.SearchUnit')"
          :allowClearingDisabled="true"
          :disabled="isViewableOnly"
          @open="handleMenuOpen"
          @select="
            (e) => {
              $emit('input', e.id);
            }
          "
          @search-change="searchAccount"
        >
          <div
            slot="option-label"
            class="tree-select-opction"
            slot-scope="{ node }"
            :title="node.label"
          >
            {{ node.label }}
          </div>
        </treeselect>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup, BAvatar } from "bootstrap-vue";
import vSelect from "vue-select";
import AccountService from "@/libs/api/account-service";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BAvatar,
    Treeselect
  },
  data() {
    return {
      accountId: this.all ? null : "",
      accountsList: [],
      unitList: [],
      currentPage: 1,
      perPage: 10,
      showLoading: false
    };
  },
  mounted() {
    if (this.$route.params && this.$route.params.account_id) {
      this.accountId = this.$route.params.account_id;
      this.$emit("input", this.accountId);
    } else if (this.all && this.$route.query && this.$route.query.account_id) {
      this.accountId = this.$route.query.account_id;
    } else if (this.value && this.value.id) {
      this.accountId = this.value.id;
    } else if (this.value) {
      this.accountId = this.value;
    } else if (localStorage.getItem("USER_ACCOUNT_ID")) {
      if (!this.all) {
        this.accountId = localStorage.getItem("USER_ACCOUNT_ID");
      }
      this.$emit("input", this.accountId);
    }

    if (this.accountsL) {
      this.accountsList = this.accountsL;
    } else {
      this.getChildAccounts();
    }
  },
  watch: {
    value(val) {
      if (val && val.id) {
        this.accountId = val.id;
      } else if (val) {
        this.accountId = val;
      }
    },

    accountId(val) {
      if (!val) {
        if (this.all) {
          this.$emit("input", this.accountId);
        }
        return;
      }
      const routeMappings = {
        events: "unit-events",
        activity: "unit-activity",
        videos: "unit-videos",
        trips: "unit-trips",
        units: "unit-map",
        history: "unit-history",
        services: "unit-services"
      };

      const selectedMenu = this.selectedMenu;
      if (selectedMenu && routeMappings[selectedMenu]) {
        this.$router
          .push({
            name: routeMappings[selectedMenu],
            params: { account_id: val },
            query: this.$route.query.filter
              ? { filter: this.$route.query.filter }
              : {}
          })
          .catch((err) => {});
      } else {
        const query = this.$route.query;
        this.$router
          .replace({
            query: { ...query, account_id: this.accountId }
          })
          .catch((err) => {});
      }
    },
    accountsL: {
      immediate: true,
      handler(newProps) {
        this.accountsList = newProps;
      }
    }
  },
  props: [
    "value",
    "selectedMenu",
    "all",
    "placeholderName",
    "accountsL",
    "isViewableOnly"
  ],
  beforeDestroy() {
    removeEventListener("scroll", (event) => {});
  },
  methods: {
    handleMenuOpen() {
      this.$nextTick(() => {
        const menu = this.$refs.treeselect.getMenu();
        const hasReachedEnd =
          menu.scrollHeight - menu.scrollTop <= menu.clientHeight * 0.25;
        menu.addEventListener("scroll", () => {
          if (hasReachedEnd) this.fetchOptions();
        });
      });
    },

    async getChildAccounts(filterAccount) {
      try {
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: filterAccount || "",
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });

        if (response && response.data) {
          this.accountsList = response.data;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    handleTreeSelect() {
      this.changeInput({
        ...this.allocationData,
        unit_driver_id: null,
        ["user.id"]: null
      });
      this.unitDriver = [];
      this.unit_driver_id = null;
    },
    searchAccount(filterAccount) {
      this.getChildAccounts(filterAccount);
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

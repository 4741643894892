<template>
  <div>
    <b-media class="align-item-center event-Tag plus-one-mobile">
      <div v-for="(tag, index) in tags" :key="index">
        <span v-if="typeof tag === 'string' ? tag : tag.name || ''">
          <div
            style="padding-right: 3px"
            class="d-flex align-items-center"
            v-if="index < maxTagShow"
          >
            <b-badge
              class="mb-1-4"
              :variant="variant ? variant : 'primary'"
              pill
              style=""
              v-b-tooltip.hover.top="{
                title:
                  (tag && tag.length > showTextInBadge) ||
                  (tag && tag.name && tag.name.length > showTextInBadge)
                    ? tag.name || tag
                    : '',
                customClass: 'tooltip-card'
              }"
            >
              {{ formattedDescriptions(tag.name || tag, showTextInBadge) }}
            </b-badge>
          </div>
        </span>
      </div>

      <b-badge
        :variant="variant ? variant : 'primary'"
        pill
        :id="event.id + `id`"
        tabindex="0"
        class="cursor-pointer plus-badge"
        v-if="tags && tags.length > maxTagShow"
        >{{
          tags.length > maxTagShow
            ? Number(tags.length) - maxTagShow + "+"
            : "+"
        }}
      </b-badge>
      <b-popover
        :target="event.id + `id`"
        triggers="focus"
        placement="lefttop"
        :show.sync="showPopover"
      >
        <div
          class="d-flex-tool"
          :style="
            tags.length ? `${'width:' + tags.length * 30}` : 'width:300px'
          "
        >
          <div v-for="(tag, index) in tags" :key="index">
            <div style="padding: 3px" class="d-flex align-items-center">
              <b-badge pill variant="primary">
                {{ typeof tag === "string" ? tag : tag.name || "" }}
              </b-badge>
            </div>
          </div>
        </div>
      </b-popover>
    </b-media>
  </div>
</template>
<script>
import { BMedia, BBadge, BPopover, VBTooltip } from "bootstrap-vue";
import TagEntity from "@/layouts/components/TagEntity.vue";
export default {
  components: {
    BMedia,
    BBadge,
    BPopover,
    TagEntity,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip
  },

  props: {
    tags: {
      type: Array,
      default: () => []
    },
    maxTagShow: {
      type: Number,
      default: () => 5
    },
    event: {
      type: Object
    },
    variant: {
      type: String
    },
    showTextInBadge: {
      type: Number,
      default: () => 7
    },
    showAllTag: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      showPopover: false
    };
  },
  watch: {},
  mounted() {},
  methods: {
    formattedDescriptions(description, len) {
      return description && description.length > len
        ? `${description.slice(0, len).trim()}...`
        : description;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.event-Tag .media-body {
  display: flex;
  flex-wrap: wrap;
}
.d-flex-tool {
  display: flex !important;
  flex-wrap: wrap;
}
.badge-icon {
  text-align: right;
  margin-bottom: 10px;
}
.mb-1-4 {
  margin-top: 2px;
  margin-bottom: 2px;
}
@media only screen and (max-width: 667px) {
  .plus-one-mobile {
    .cursor-pointer.badge-primary.plus-badge {
      // max-height: 20px;
    }
  }
}
</style>

<template>
  <div>
    <div
      class="geo-search"
      :style="groupName ? { 'justify-content': 'end' } : ''"
    >
      <div
        class="input-group-merge"
        :style="groupName ? { 'max-width': '300px' } : ''"
        :class="
          !checkAbility({
            action: constants.PERMISSIONS_ACTION.ADD,
            subject: constants.PERMISSIONS_MODEL.GEOFENCES
          }) && !this.groupName
            ? 'input-group-merge-full'
            : ''
        "
      >
        <search
          v-model="searchFiled"
          :searchColumn="searchColumn"
          :hideLabel="true"
          :operator="operator"
        ></search>
      </div>
      <b-button
        @click="redirectAddGeoFence(null)"
        variant="primary"
        class="btn-icon"
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.ADD,
            subject: constants.PERMISSIONS_MODEL.GEOFENCES
          }) && !this.groupName
        "
      >
        <feather-icon icon="PlusIcon" />
        <span class="d-none d-sm-inline ml-1">{{ $t("Geofence.AddNew") }}</span>
      </b-button>
    </div>
    <div
      class="search-outer padding-btm-0 mb-1 mt-1"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.GEOFENCES_SUB_ACCOUNT,
          subject: constants.PERMISSIONS_MODEL.GEOFENCES
        }) && !groupName
      "
    >
      <AccountSearch
        all="true"
        class="unit-search-v2"
        v-model="selectedAccount"
      />
    </div>
    <div class="table-responsive" v-if="showLoading">
      <b-skeleton-table
        :rows="6"
        :columns="2"
        :table-props="{ bordered: true, striped: true }"
      />
    </div>
    <div v-else>
      <div class="geo-outer">
        <div class="d-flex justify-content-between align-items-center mb-1">
          <div class="">
            <!-- dfddf {{ unitsData }} -->
            <b-form-checkbox
              v-if="totalGeofence && !groupName"
              style="min-width: 10px; margin-left: 4px"
              :title="$t('route.SelectAll')"
              v-model="isAllSelected"
              @change="clearAll"
              ><span class="ml-1">{{
                $t("route.SelectAll")
              }}</span></b-form-checkbox
            >
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <b-form-checkbox
              v-if="!groupName"
              v-model="isAllowChild"
              v-b-tooltip.hover="{
                title: isAllowChild
                  ? $t('tooTip.HideInheritedItems')
                  : $t('tooTip.ShowInheritedItems'),
                customClass: 'device-tooltip-class '
              }"
              switch
              class="mx-h"
            ></b-form-checkbox>

            <feather-icon
              @click="deleteSelectedGeofence()"
              v-b-tooltip.hover="{
                title: $t('tooTip.DeleteSelected'),
                customClass: 'device-tooltip-class'
              }"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.DELETE,
                  subject: constants.PERMISSIONS_MODEL.ROUTES
                }) &&
                (isAllSelected || selectedGeoFencesIds.length > 1)
              "
              icon="Trash2Icon"
              class="text-danger mrl_select"
              size="18"
            ></feather-icon>
          </div>
        </div>
        <b-list-group v-if="totalGeofence !== 0 && !showLoading">
          <virtual-list
            style="overflow-y: auto"
            :style="{ height: 'calc(100vh - 270px)' }"
            :data-key="'id'"
            :data-sources="items"
            :estimate-size="10"
            :dataComponent="{}"
            v-on:tobottom="handleScroll"
          >
            <template #item="{ item: item }">
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <div class="check-outer">
                  <b-form-checkbox
                    v-if="!groupName"
                    v-model="item.selected"
                    @change="clickSelectedGeoFence(item, $event)"
                  >
                    <div class="d-flex align-items-center">
                      <div class="poly-outer">
                        <PolygonImg
                          v-if="item.color"
                          :style="{ fill: item.color }"
                        />
                      </div>
                      <span
                        :title="
                          item.name && item.name.length > 15 ? item.name : ''
                        "
                        class="geo-name"
                        >{{ item.name }}</span
                      >
                    </div>
                  </b-form-checkbox>
                  <div class="d-flex align-items-center" v-if="groupName">
                    <div class="poly-outer">
                      <PolygonImg
                        v-if="item.color"
                        :style="{ fill: item.color }"
                      />
                    </div>
                    <span
                      :title="
                        item.name && item.name.length > 15 ? item.name : ''
                      "
                      class="geo-name"
                      >{{ item.name }}</span
                    >
                  </div>
                </div>
                <div class="icon-block">
                  <b-link
                    v-if="
                      checkAbility({
                        action: constants.PERMISSIONS_ACTION.READ,
                        subject: constants.PERMISSIONS_MODEL.GEOFENCES
                      })
                    "
                    :to="{
                      name: 'geofence-view',
                      params: { id: item.id },
                      query: { account_id: $route.query.account_id }
                    }"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="text-primary mrl"
                      size="18"
                      v-b-tooltip.hover="{
                        title: $t('tooTip.view'),
                        customClass: 'device-tooltip-class'
                      }"
                    ></feather-icon>
                  </b-link>
                  <b-link
                    v-if="
                      item.is_editable == true &&
                      checkAbility({
                        action: constants.PERMISSIONS_ACTION.UPDATE,
                        subject: constants.PERMISSIONS_MODEL.GEOFENCES
                      })
                    "
                    :to="{
                      name: 'geofence-edit',
                      params: { id: item.id },
                      query: { account_id: $route.query.account_id }
                    }"
                  >
                    <feather-icon
                      v-b-tooltip.hover="{
                        title: $t('tooTip.update'),
                        customClass: 'device-tooltip-class'
                      }"
                      icon="EditIcon"
                      class="text-primary mrl"
                      size="18"
                    ></feather-icon>
                  </b-link>
                  <b-link
                    @click="redirectAddGeoFence(item.id)"
                    v-if="
                      checkAbility({
                        action: constants.PERMISSIONS_ACTION.ADD,
                        subject: constants.PERMISSIONS_MODEL.GEOFENCES
                      })
                    "
                  >
                    <feather-icon
                      v-b-tooltip.hover="{
                        title: $t('tooTip.clone'),
                        customClass: 'device-tooltip-class'
                      }"
                      icon="CopyIcon"
                      class="text-primary mrl"
                      size="18"
                    ></feather-icon
                  ></b-link>
                  <b-link @click="deleteGeofence(item)">
                    <feather-icon
                      v-b-tooltip.hover="{
                        title: $t('tooTip.delete'),
                        customClass: 'device-tooltip-class'
                      }"
                      v-if="
                        item.is_editable == true &&
                        checkAbility({
                          action: constants.PERMISSIONS_ACTION.DELETE,
                          subject: constants.PERMISSIONS_MODEL.GEOFENCES
                        })
                      "
                      icon="Trash2Icon"
                      class="text-danger mrl"
                      size="18"
                    ></feather-icon
                  ></b-link>
                </div>
              </b-list-group-item>
            </template>
          </virtual-list>
        </b-list-group>
        <div v-if="totalGeofence === 0 && !showLoading">
          <b-jumbotron :lead="$t('NoMatchingRecordsFound')"> </b-jumbotron>
        </div>
      </div>
    </div>
    <AreYouSureModal
      :data="deleteData"
      :onClose="onClose"
      :removedUser="removeGeofence"
      :customData="customData"
    />
    <DeleteGeofence
      :data="{
        ...removedGeofenceData,
        modelName: 'modal-sm-remove-geofence-warring'
      }"
      :onClose="onClose"
      :removedUser="onClose"
    />
  </div>
</template>

<script>
import {
  BJumbotron,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormCheckbox,
  BAvatar,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BSkeletonTable,
  BLink,
  VBTooltip
} from "bootstrap-vue";
import PolygonImg from "@/assets/images/polygon.svg";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from "@/EventBus";
import Loader from "@/layouts/components/Loader.vue";
import store from "@/store";
import VirtualList from "vue-virtual-scroll-list";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import UtilityService from "@/libs/api/utility-service";
import Search from "@/views/Common/search.vue";
import AccountSearch from "../Unit/Unit-V2/AccountSearch.vue";
import DeleteGeofence from "./DeleteGeofence.vue";
export default {
  components: {
    Loader,
    BJumbotron,
    DynamicBadge,
    AreYouSureModal,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormCheckbox,
    BAvatar,
    PolygonImg,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BSkeletonTable,
    VirtualList,
    BLink,
    Search,
    AccountSearch,
    DeleteGeofence
  },
  data() {
    return {
      maxHeight: 0,
      currentPage: 1,
      perPage: 1,
      rows: 100,
      items: [],
      totalGeofence: 0,
      selectedGeoFences: [],
      isAllSelected: false,
      selectedAccount:
        this.$route.query.account_id || localStorage.getItem("USER_ACCOUNT_ID"),
      searchColumn: ["name"],
      searchFiled: {},
      operator: "ilike",
      selected: "10",
      option: ["10", "20", "30"],
      page: 1,
      page_size: parseInt(window.innerHeight / 57),
      totalUnits: 0,
      showFilter: false,

      selectedCheckbox: [],
      currentPage: 1,
      isAllowChild: true,
      unitsData: [],
      selectedGeoFencesIds: [],
      deleteData: null,
      removedGeofenceData: null,
      customData: null,
      filter: {},
      unitFilter: {},

      showLoading: "false",

      currentUnit: null
    };
  },
  mounted() {
    // EventBus.$emit("geofence-get-shape", {
    //   isClearAll: true
    // });
    this.debounceGetAllGeoFences();
    setTimeout(() => {
      this.setMaxHeight1();
    }, 1000);

    window.addEventListener("resize", this.setMaxHeight1);
  },
  watch: {
    searchFiled(val) {
      this.filter = val;
      this.currentPage = 1;
      this.debounceGetAllGeoFences();
    },
    isAllowChild(val) {
      this.currentPage = 1;
      this.debounceGetAllGeoFences();
    },
    selectedAccount(val) {
      this.currentPage = 1;
      this.debounceGetAllGeoFences();
    }
  },
  props: ["geofenceControl", "groupName"],
  beforeDestroy() {
    window.removeEventListener("resize", this.setMaxHeight1);
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  methods: {
    close() {},
    handleScroll() {
      if (this.totalGeofence > this.currentPage * this.page_size) {
        const me = this;
        me.currentPage++;
        me.getAllGeoFences(true);
      }
    },
    clearAll() {
      if (!this.isAllSelected) {
        this.items.forEach((e) => {
          this.clickSelectedGeoFence(e, false);
        });
      } else {
        this.items.forEach((e) => {
          this.clickSelectedGeoFence(e, true);
        });
      }
    },
    setMaxHeight1() {
      if (this.$refs.dynamicHeightDiv1) {
        const windowHeight = window.innerHeight;
        const offset = 250; // Example offset value
        this.maxHeight = windowHeight - offset;
        this.$refs.dynamicHeightDiv1.style.height = `${this.maxHeight}px`;
      }
    },
    redirectAddGeoFence(id) {
      let query = {};
      if (id) {
        query.id = id;
      }
      this.$router.push({ name: "geofence-create", query });
    },
    clearAll() {
      if (!this.isAllSelected) {
        this.items.forEach((e) => {
          this.clickSelectedGeoFence(e, false);
        });
      } else {
        this.items.forEach((e) => {
          this.clickSelectedGeoFence(e, true);
        });
      }
    },
    clickSelectedGeoFence(item, flag) {
      if (flag) {
        this.geofenceControl.addLayer({
          type: item.boundary.type
            ? item.boundary.type.toLowerCase()
            : item.boundary.type,
          coordinates: item.boundary.coordinates,
          id: item.id,
          visibility_start: item.visibility_start,
          visibility_end: item.visibility_end,
          color: item.color,
          radius: item.boundary.radius
        });
        const isAllSelected = this.items.find((e) => !e.selected);
        if (!isAllSelected || !isAllSelected.id) {
          this.isAllSelected = true;
        }
        if (item.is_editable) {
          this.selectedGeoFencesIds.push(item.id);
        }
      } else {
        this.geofenceControl.removeLayerById(item.id);
        this.isAllSelected = false;
        this.selectedGeoFencesIds = this.selectedGeoFencesIds.filter(
          (e) => e !== item.id
        );
      }

      const index = this.items.findIndex((e) => e.id === item.id);
      if (index !== -1) {
        this.items[index].selected = flag;
      }
    },
    async getAllGeoFences(isScroll) {
      try {
        if (!isScroll) {
          this.showLoading = true;
        }
        const me = this;
        const requestData = {
          page: parseInt(this.currentPage),
          page_size: this.page_size || parseInt(window.innerHeight / 57),
          account_id:
            this.$route.query.account_id ||
            this.selectedAccount ||
            localStorage.getItem("USER_ACCOUNT_ID"),
          is_allow_child: this.isAllowChild
        };
        if (this.groupName) {
          requestData.label = this.groupName;
        }

        requestData.filters =
          this.filter && Array.isArray(this.filter)
            ? this.filter
            : this.filter && this.filter.value
            ? [this.filter]
            : [];

        if (this.groupName) {
          requestData.label = this.groupName;
        }

        let response = await new UtilityService().getAllGeoFences(requestData);
        this.showLoading = false;

        if (response && response.data) {
          const geoFenceItem = response.data.list || [];

          geoFenceItem.forEach((element) => {
            if (this.isAllSelected) {
              element.selected = true;
              this.clickSelectedGeoFence(element, true);
            } else {
              element.selected = false;
            }
          });

          this.items = isScroll
            ? [...this.items, ...geoFenceItem]
            : geoFenceItem;
          this.totalGeofence = response.data.pagination.total_records;
          setTimeout(() => {
            this.setMaxHeight1();
          }, 200);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },
    debounceGetAllGeoFences() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllGeoFences();
      }, 300); // Adjust the delay as necessary
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },

    onClose(isReload) {
      if (isReload) {
        this.debounceGetAllGeoFences();
      }

      this.$bvModal.hide("modal-sm-remove");
      this.$bvModal.hide("modal-sm-remove-geofence-warring");
      this.deleteData = null;
      this.customData = null;
      this.errorDeleteData = null;
    },
    async removeGeofence(props) {
      try {
        this.showLoading = true;
        let req = {};
        if (this.customData && this.customData.msgTxt) {
          if (this.isAllSelected) {
            req.isAllSelected = true;
          } else {
            req.geofence_ids = this.selectedGeoFencesIds;
          }
        } else {
          req.geofence_ids = [props.id];
        }
        req.account_id = this.$route.query.account_id;
        let response = await new UtilityService().deleteGeofence(req);
        this.showLoading = false;
        if (response && response.data && response.data.success) {
          if (this.customData && this.customData.msgTxt) {
            if (this.isAllSelected) {
              this.geofenceControl.removeAllLayers();
              this.isAllSelected = false;
            } else {
              for (let i = 0; i < this.selectedGeoFencesIds.length; i++) {
                this.geofenceControl.removeLayerById(
                  this.selectedGeoFencesIds[i]
                );
              }
            }
            this.selectedGeoFencesIds = [];
          } else {
            this.selectedGeoFencesIds = this.selectedGeoFencesIds.filter(
              (e) => e !== props.id
            );
          }
          this.onClose(true);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("geofence.DeletedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.geofenceControl.removeLayerById(props.id);
          this.debounceGetAllGeoFences();
        }
        if (
          response &&
          response.data &&
          !response.data.success &&
          response.data.list &&
          response.data.list.length
        ) {
          this.$bvModal.hide("modal-sm-remove");
          this.removedGeofenceData = {
            showData: response.data.list.slice(0, 50),
            showDataCount:
              response.data.list.length > 50
                ? response.data.list.length - 50
                : 0,
            ...response.data.list
          };
          setTimeout(() => {
            this.$bvModal.show("modal-sm-remove-geofence-warring");
          }, 100);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },

    deleteGeofence(props) {
      this.$bvModal.show("modal-sm-remove");

      this.deleteData = {
        name: props.name,
        id: props.id
      };
      this.customData = null;
    },
    deleteSelectedGeofence() {
      this.$bvModal.show("modal-sm-remove");
      this.deleteData = {
        id: ""
      };

      this.customData = {
        msgTxt: this.$t("geofence.AreYouSureYouWantToDeleteAllSelectedGeofence")
      };
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.debounceGetAllGeoFences();
    }
  }
};
</script>
<style lang="scss">
.mx-h {
  .custom-control-label::before {
    max-height: 1.55rem !important;
  }
  .custom-control-label::after {
    max-height: 1.55rem !important;
  }
}
</style>
<style lang="scss" scoped>
.mrl_select {
  cursor: pointer;
  margin-left: 7px;
  margin-right: 10px;
}
.geo-outer {
  padding: 15px 0px;
  overflow-y: auto;
  .list-group-item {
    border: none;
    border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }
  .icon-block {
    display: flex;
  }
  .poly-outer {
    height: 35px;
    width: 35px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    margin-left: 10px;
    margin-right: 10px;
  }
  .mrl {
    cursor: pointer;
    margin-left: 7px;
    margin-right: 7px;
  }
}
.pagination-outer-1 {
  text-align: center;
  display: flex;
  justify-content: center;
}
.geo-search {
  display: flex;
  padding: 0 0;
  margin-bottom: 5px;
  .input-group-merge {
    width: calc(100% - 138px);
    margin-right: 15px;
  }
  .input-group-merge.input-group-merge-full {
    width: 100%;
    margin-right: 0;
    .search-filter {
      min-width: 100%;
    }
  }
  .btn-icon {
    width: 120px;
    padding: 0 10px;
  }
}
.dark-layout .jumbotron {
  background: var(--unit-bg-dark);
}
</style>
<style lang="scss">
.check-outer {
  .custom-checkbox {
    .custom-control-label {
      cursor: pointer;
    }
    .custom-control-label::before,
    .custom-control-label::after {
      top: 23%;
      bottom: 23%;
    }
  }
}
.geo-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
  min-width: 130px;
  max-width: 131px;
  display: block;
}
.input-group-merge.input-group-merge-full {
  .form-group {
    width: 100% !important;
  }
}
.geo-outer .list-group-item {
  padding: 0.75rem 0.25rem !important;
}
</style>

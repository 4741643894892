<template>
  <b-modal
    :id="data && data.modelName ? data.modelName : modelName"
    centered
    size="sm"
    modal-class="no-header-modal"
  >
    <b-card-text>
      <div class="d-flex justify-content-center">
        <b-avatar variant="danger" size="96" class="size-96">
          <feather-icon size="56" :icon="'Trash2Icon'" />
        </b-avatar>
      </div>
      <div>
        <div class="you-invite">
          <!-- <span class="text-primary">{{ data.name }}</span> -->
          {{ $t("RemoveAlert.AlertremoTitle") }}
          {{ $t("RemoveAlert.AlertremoTitle1") }}
        </div>
        <!-- <div class="you-invite">
        <b>{{ $t("RemoveAlert.Notification") }}</b>
      </div> -->
        <div class="you-invite width-210">
          <div
            class="text-primary alt-name curserPointer"
            v-for="list in data.showData"
            :key="list.id"
          >
            <b-link
              :to="{
                name: 'notification-view',
                target: '_blank',
                params: { id: list.id }
              }"
            >
              {{ `- ${list.name}` }}</b-link
            >
          </div>

          <div class="text-primary alt-name curserPointer">
            <span @click="redirectNotification(data.id)">
              {{
                data.showDataCount > 0
                  ? `+${data.showDataCount} ${$t("RemoveAlert.More")}`
                  : ""
              }}</span
            >
          </div>
        </div>
        <div class="d-flex">
          <div class="you-invite">
            {{ $t("RemoveAlert.RemoveAlertDesc") }}
          </div>
        </div>
        <div class="you-invite">
          {{ $t("RemoveAlert.YouSure") }}
          <span class="text-primary"> "{{ data.name }}"? </span>
        </div>
      </div>
      <div class="d-flex justify-content-center mb-20 mtt-20">
        <b-button
          v-if="!customData || !customData.isQuite"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          @click="removedUser({ ...data, id: data.id })"
          class="mt-2 mr-1"
        >
          {{
            customData && customData.btnTxtRemove
              ? customData.btnTxtRemove
              : $t("user.Remove")
          }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          @click="onClose()"
          class="mt-2"
        >
          {{
            customData && customData.btnTxt
              ? customData.btnTxt
              : $t("user.Cancel")
          }}
        </b-button>
      </div>
    </b-card-text>
  </b-modal>
</template>
<script>
import { BCard, BLink, BButton, BAvatar, BCardText } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
export default {
  name: "AreYouSureModal",
  components: {
    BCard,
    BButton,
    BAvatar,
    BLink,
    BCardText
  },

  directives: {
    Ripple
  },
  watch: {
    data(old, newVal) {}
  },
  props: ["data", "user", "component", "onClose", "removedUser", "customData"],
  data() {
    return {
      modelName: "modal-sm-remove"
      // status: false
    };
  },
  methods: {
    redirectNotification(id) {
      this.$router.push({
        target: "_blank",
        name: "notification-list",
        query: { alert_id: id }
      });
    }
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.delete-conf {
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
  margin-top: 15px;
}
.width-210 {
  width: 240px;
  margin: 0;
  .alt-name {
    text-align: left;
  }
}
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: start;
  color: $body-color;
  margin-top: 10px;
  .alt-name {
    padding-right: 3px;
  }
}
.invite-name {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  font-weight: 600;
  word-break: break-all;
}
.mb-20 {
  margin-bottom: 20px;
}
.mtt-20 {
  margin-top: 0px;
}
</style>

<template>
  <div>
    <b-card class="report-page">
      <div
        class="report-title d-flex align-items-center justify-content-between"
      >
        <h4 class="card-title">
          {{ $t("ReportsData.ReportsTitle") }}
        </h4>
        <div class="filter-btn-outer-new">
          <b-button
            v-b-tooltip.hover.v-primary
            :title="$t('ReportsData.CreateReport')"
            variant="primary"
            class="mr-1 desktop-hidden"
            :to="{ name: 'select-report' }"
            v-if="
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.REPORTS
              }) && !isGroupEntity
            "
          >
            <feather-icon icon="PlusIcon" size="12" />
            <!-- {{ $t("ReportsData.CreateReport") }} -->
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="desktop-hidden"
            @click="showFiltermenu = !showFiltermenu"
          >
            <feather-icon icon="FilterIcon" size="15" />
          </b-button>
        </div>

        <div
          class="d-flex flex-wrap filter-card search-display-1"
          v-if="showFiltermenu"
        >
          <div class="notification-per-page">
            <div class="addXIcon">
              <feather-icon
                class="sizeIcon"
                @click="showFiltermenu = !showFiltermenu"
                icon="XIcon"
                size="15"
              />
            </div>
            <label class="search-label mt-1">{{
              $t("Notification.Entries")
            }}</label>
            <v-select
              :clearable="false"
              id="vue-select"
              class="per-page-selector d-inline-block fix-83"
              v-model="perPage"
              :options="option"
            />
          </div>
          <search
            v-if="!isGroupEntity"
            class="mobile-margin-top no-btm-0"
            style="float: left"
            v-model="searchFiled"
            :searchColumn="searchColumn"
            :operator="operator"
          ></search>
          <b-button
            v-b-tooltip.hover.v-primary
            :title="$t('ReportsData.CreateReport')"
            variant="primary"
            class="mobile-hidden"
            :to="{ name: 'select-report' }"
            v-if="
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.REPORTS
              }) && !isGroupEntity
            "
          >
            <feather-icon icon="PlusIcon" size="12" />
            <span class="mobile-hidden">{{
              $t("ReportsData.CreateReport")
            }}</span>
          </b-button>
        </div>
      </div>
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="this.perPage || 10"
          :columns="7"
          :table-props="{ bordered: true, striped: true }"
          class="device-list2"
        />
      </div>
      <b-table
        class="position-relative report-table dre-table"
        responsive
        show-empty
        v-if="!show"
        sticky-header
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
        :hover="$route.name === 'notification-create'"
      >
        <template #cell(report_name)="row">
          {{ row.item.report_name }}
        </template>
        <template #cell(report_type)="row">
          {{ row.item.processor }}
        </template>
        <template #cell(generated)="row">
          {{
            socketId == row.item.id
              ? socketTime
              : DateTimeConvert(row.item.mtime)
          }}
        </template>

        <template #cell(labels)="row">
          <div class="parent" id="parent">
            <DynamicBadge
              :isList="true"
              :entityType="'REPORT'"
              :tagsData="row.item.labels"
              :showTextInBadge="Number(7)"
              :event="{ ...row.item, id: row.item.id }"
              :maxTagShow="Number(defaultBadgeShow)"
              :listApi="debounceGetAllReports"
              class="try"
            ></DynamicBadge>
            <b-badge
              class="badge-cust"
              :variant="resolveFilterBadgeColor(row.item.status)"
            >
            </b-badge>
          </div>
        </template>
        <template #cell(status)="row">
          <b-progress
            :value="row.item.progress"
            max="100"
            class="progress-bar-success"
            variant="success"
            v-if="
              row.item.status === 'PROCESSING' &&
              row.item.progress < 100 &&
              row.item.recent_time <= 5
            "
          />
          <feather-icon
            v-if="
              row.item.status === 'FAILED' ||
              (row.item.recent_time > 5 && row.item.status === 'PROCESSING')
            "
            class="text-danger"
            icon="AlertTriangleIcon"
            v-b-tooltip.hover.top="
              (row.item.error != 0 && row.item.error) || ''
            "
            size="20"
          />

          <feather-icon
            v-if="
              row.item.status === 'COMPLETED' ||
              row.item.status >= 100 ||
              (row.item.formats && row.item.formats.length)
            "
            class="text-success"
            icon="CheckCircleIcon"
            size="20"
          />
        </template>
        <template #cell(formats)="row">
          <span class="d-flex">
            <div v-for="item in row.item.formats" :key="item.id">
              <ExlReportIcon
                v-if="item.extension === 'EXCEL' && item.file_id"
                @click="downloadReport(item.file_id)"
                v-b-tooltip.hover.v-primary
                :title="$t('ReportsData.EXL')"
                :class="
                  !item.file_id
                    ? `disabled-report-icon report-icon ${row.item.status}`
                    : `cursor-pointer report-icon ${row.item.status}`
                "
              />
              <PdfReportIcon
                :class="
                  !item.file_id
                    ? `disabled-report-icon report-icon ${row.item.status}`
                    : `cursor-pointer report-icon ${row.item.status}`
                "
                v-b-tooltip.hover.v-primary
                :title="$t('ReportsData.PDF')"
                v-if="item.extension === 'PDF'"
                @click="downloadReport(item.file_id)"
              />
              <DocReportIcon
                :class="
                  !item.file_id
                    ? `disabled-report-icon report-icon ${row.item.status}`
                    : `cursor-pointer report-icon ${row.item.status}`
                "
                v-b-tooltip.hover.v-primary
                :title="$t('ReportsData.DOC')"
                v-if="item.extension === 'DOC' && item.file_id"
                @click="downloadReport(item.file_id)"
              />
              <ChartReportIcon
                v-if="item.extension === 'CHR' && item.file_id"
                @click="downloadReport(item.file_id)"
                v-b-tooltip.hover.v-primary
                :title="$t('ReportsData.CHAT')"
                :class="
                  !item.file_id
                    ? `disabled-report-icon report-icon ${row.item.status}`
                    : `cursor-pointer report-icon ${row.item.status}`
                "
              />
              <VideoReportIcon
                class="report-icon"
                v-if="item.extension === 'VID' && item.file_id"
                @click="downloadReport(item.file_id)"
                v-b-tooltip.hover.v-primary
                :title="$t('ReportsData.VIDEO')"
                :class="
                  !item.file_id
                    ? `disabled-report-icon report-icon ${row.item.status}`
                    : `cursor-pointer report-icon ${row.item.status}`
                "
              /></div
          ></span>
        </template>
        <template #cell(ACTIONS)="row">
          <div class="text-left min-80 d-flex justify-content-end">
            <span>
              <b-link
                :to="{
                  name: 'edit-report',
                  params: {
                    id: row.item.id
                  }
                }"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.UPDATE,
                    subject: constants.PERMISSIONS_MODEL.REPORTS
                  }) && row.item.sensors
                "
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.update')"
                  icon="EditIcon"
                  class="text-primary action-icon cursor-pointer"
                  size="18"
                />
              </b-link>
            </span>
            <span>
              <b-link
                :to="{
                  name: 'view-report',
                  params: { id: row.item.id }
                }"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.READ,
                    subject: constants.PERMISSIONS_MODEL.REPORTS
                  }) && row.item.sensors
                "
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.view')"
                  icon="EyeIcon"
                  class="text-primary action-icon cursor-pointe"
                  size="18"
                />
              </b-link>
            </span>
            <span>
              <feather-icon
                v-b-tooltip.hover.top="$t('tooTip.delete')"
                icon="Trash2Icon"
                class="text-danger action-icon"
                size="18"
                v-b-modal.modal-sm-remove
                @click="deleteReport(row.item)"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.DELETE,
                    subject: constants.PERMISSIONS_MODEL.REPORTS
                  })
                "
              />
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalReport > 0"
              v-model="currentPage"
              :total-rows="totalReport"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :no-paging-nav="false"
              :active-class="activeClass"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- <Loader :show="show" /> -->
    <AreYouSureModal
      :data="deleteData"
      :status="status"
      :onClose="onClose"
      :removedUser="removeReport"
    />
  </div>
</template>

<script>
import {
  BCard,
  BAlert,
  BButton,
  BRow,
  BCol,
  BImg,
  BTable,
  BProgress,
  BPagination,
  VBTooltip,
  BBadge,
  BLink,
  BSkeletonTable
} from "bootstrap-vue";
import ReportService from "@/libs/api/report-service";
import * as moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ChartReportIcon from "@/assets/images/new-icon/chart-report.svg";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import DocReportIcon from "@/assets/images/new-icon/doc.svg";
import ExlReportIcon from "@/assets/images/new-icon/exl.svg";
import Loader from "@/layouts/components/Loader.vue";
import PdfReportIcon from "@/assets/images/new-icon/pdf.svg";
import VideoReportIcon from "@/assets/images/new-icon/video-report.svg";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import SocketIOService from "@/libs/socket/sockets";
import Search from "../Common/search.vue";
import vSelect from "vue-select";
import constants from "@/utils/constants";
export default {
  components: {
    BCard,
    BAlert,
    BButton,
    BRow,
    BCol,
    BImg,
    BTable,
    BLink,
    ChartReportIcon,
    DocReportIcon,
    ExlReportIcon,
    PdfReportIcon,
    VideoReportIcon,
    BProgress,
    BPagination,
    VBTooltip,
    Search,
    vSelect,
    BBadge,
    Loader,
    DynamicBadge,
    AreYouSureModal,
    BSkeletonTable
  },
  props: ["isGroupEntity", "groupName"],
  data() {
    return {
      showFiltermenu: false,
      show: false,
      activeClass: "active",
      statusBarValue: 0,
      socketFile: null,
      perPage: this.$route.query.perPage ? this.$route.query.perPage : this.nearestPage(["10", "20", "30"],parseInt(window.innerHeight / 45)),
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      status: false,
      items: [],
      totalReport: 0,
      option: ["10", "20", "30"],
      searchColumn: ["name"],
      searchFiled: {},
      operator: "ilike",
      socketData: {},
      deleteData: null,
      socketId: null,
      socketTime: null,
      defaultBadgeShow: 2,
      timeoutId: null,
      tableColumns: [
        {
          key: "report_name",
          tdClass: "mobile-no-wrap text-left",
          thClass: "text-left",
          label: this.$t("ReportsData.tableColumns.reportName")
        },
        {
          key: "report_type",
          tdClass: "",
          thClass: "",
          label: this.$t("ReportsData.tableColumns.reportType")
        },
        {
          key: "generated",
          tdClass: "mobile-no-wrap",
          thClass: "",
          label: this.$t("ReportsData.tableColumns.generated")
        },
        {
          key: "labels",
          tdClass: " label-w-a badge-230",
          thClass: " label-w-a badge-230",
          label: this.$t("ReportsData.tableColumns.labels")
        },
        {
          key: "status",
          tdClass: "text-center min-180",
          thClass: "text-center min-180",
          label: this.$t("ReportsData.tableColumns.status")
        },
        {
          key: "formats",
          tdClass: " label-w-a",
          thClass: " label-w-a",
          label: this.$t("ReportsData.tableColumns.download")
        },
        {
          key: "ACTIONS",
          thClass: "action-width-report",
          tdClass: "action-width-report",
          label: this.$t("ReportsData.tableColumns.actions")
        }
      ]
    };
  },
  setup() {
    const resolveFilterBadgeColor = (filter) => {
      filter = filter && filter.toUpperCase();
      const status = {
        ACTIVE: "light-success",
        INACTIVE: "light-warning",
        DAMAGED: "light-danger",
        BLOCKED: "light-secondary"
      };

      const selectedStatus =
        filter && status[filter] ? status[filter] : status["ACTIVE"];

      return selectedStatus;
    };
    return {
      resolveFilterBadgeColor
    };
  },
  watch: {
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      if (this.perPage * (this.currentPage - 1) > this.totalReport) {
        this.currentPage = 1;
        return;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.debounceGetAllReports();
    },
    currentPage(newPage) {
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.debounceGetAllReports();
    },
    searchFiled(newVal, oldVal) {
      this.filter = newVal;
      this.debounceGetAllReports();
    }
  },
  directives: {
    "b-tooltip": VBTooltip
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("resize", this.showLabelSize);
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
    if (this.socketData) {
      this.socketData = {};
    }
  },
  computed: {
    computedSocketObject() {
      return this.socketData; // Or any necessary transformation of myObject
    }
  },
  methods: {
    updateUrl(e) {
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },
    showLabelSize() {
      setTimeout(() => {
        const parentElement = document.querySelector(".parent");
        if (parentElement) {
          const parentWidth = parentElement.offsetWidth;
          this.defaultBadgeShow = Math.floor(parentWidth / 71);
        }
      }, 200);
    },
    onClose() {
      this.debounceGetAllReports();
      this.$bvModal.hide("modal-sm-remove");
    },
    async removeReport(props) {
      try {
        this.show = true;
        this.onClose();
        let response = await new ReportService().deleteReport({
          report_id: props.id
        });
        this.show = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("ReportsData.DeletedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.debounceGetAllReports();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    deleteReport(props) {
      this.status = true;
      this.deleteData = { name: props.report_name, id: props.id };
    },
    calculateProgressValue(reportId) {
      if (
        this.computedSocketObject[reportId] &&
        this.computedSocketObject[reportId].progress
      ) {
        console.log(
          "live progress value:",
          this.computedSocketObject[reportId].progress
        );
      }
      return (
        this.computedSocketObject[reportId] &&
        this.computedSocketObject[reportId].progress &&
        this.computedSocketObject[reportId].progress != 100 &&
        this.computedSocketObject[reportId].progress
      );
    },
    findFileIdInSocket(TYPE, reportId) {
      const result =
        this.computedSocketObject[reportId] &&
        this.computedSocketObject[reportId].progress == "100" &&
        this.computedSocketObject[reportId].files &&
        this.computedSocketObject[reportId].files.find(
          (file) => file.extension == TYPE
        );
      return result && result.file_id;
    },
    async downloadReport(url) {
      try {
        if (url) {
          const report_url = url;
          const link = document.createElement("a");

          link.href = report_url;
          link.target = "_blank";

          link.setAttribute("download", "report");

          document.body.appendChild(link);

          link.click();
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    async integrateSocket() {
      try {
        if (this.socket) {
          this.socket.disconnect();
          this.socket = null;
        }
        this.socket = new SocketIOService();
        await this.socket.setupSocketConnection();
        for (let i = 0; i < this.items.length; i++) {
          if (
            this.items[i].status == "PROCESSING" ||
            this.items[i].status == "NOT_STARTED"
          ) {
            this.socket.subscribeEventWithFunction(
              `REPORT_PROGRESS${this.items[i].id}`,
              (data) => {
                this.items[i].status = data.status.status;
                this.items[i].error = data.status.error;
                this.items[i].progress = data.progress;
                if (Number(data.progress) >= 100) {
                  this.items[i].formats = data.files;
                  this.socket.unsubscribeEventWithFunction(
                    `REPORT_PROGRESS${this.items[i].id}`
                  );
                }
              }
            );
          }
        }
      } catch (error) {}
    },
    debounceGetAllReports() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllReport();
      }, 300); // Adjust the delay as necessary
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.debounceGetAllReports();
    },
    async getAllReport() {
      try {
        this.show = true;
        const me = this;
        const requestData = {
          page: parseInt(this.currentPage),
          page_size: parseInt(this.perPage),
          sorts: []
        };
        if (this.groupName) {
          if (Array.isArray(this.filter)) {
            const group = this.groupName;
            this.filter.findIndex((item) => item.value == group) < 0 &&
              this.filter.push({
                field: "labels",
                operator: "ilike",
                value: this.groupName
              });
          } else {
            this.filter = [
              { field: "labels", operator: "ilike", value: this.groupName }
            ];
          }
        }

        requestData.filters =
          this.filter && Array.isArray(this.filter)
            ? this.filter
            : this.filter && this.filter.value
            ? [this.filter]
            : [];
        this.socketData = {};
        let response = await new ReportService().getAllCreatedReport(
          requestData
        );
        this.show = false;
        if (response && response.data) {
          this.items = response.data.list || [];
          this.totalReport = response.data.pagination.total_records;
          // if (
          //   this.currentPage * this.perPage >
          //   this.totalReport + this.perPage
          // ) {
          //   this.currentPage = 1;
          //   return;
          // }
          if (!this.items.length && requestData.filters.length == 0) {
            this.$router.push({ name: "empty-report" });
            return;
          }
          const inProgressReports = this.items.filter((e) =>
            ["PROCESSING", "NOT_STARTED"].includes(e.status)
          );
          if (inProgressReports && inProgressReports.length) {
            await this.integrateSocket();
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
      this.showLabelSize();
    }
  },
  handlePageChange(newPage) {
    this.updateQueryParam();
  },
  updateQueryParam() {
    const query = {
      ...this.$route.query,
      perPage: this.perPage.toString(),
      page: this.currentPage.toString()
    };
    if (this.filter && this.filter.value) {
      query.filter = this.filter.value;
    }
    this.$router.replace({ query }).catch(() => {});
  },
  mounted() {
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }
      this.debounceGetAllReports();
    });
    if (this.groupName) {
      this.debounceGetAllReports();
    }
    window.addEventListener("resize", this.showLabelSize);
  }
};
</script>
<style lang="scss">
.action-width-report {
  max-width: 175px;
  width: 175px;
  min-width: 175px;
  text-align: end !important;
}
.dre-table {
  tbody td,
  tr th {
    text-align: center;
  }
}
.try .media-body {
  display: flex;
  // justify-content: center !important;
}
.label-w-a {
  div {
    text-align: left;
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.report-page {
  min-height: 70vh;
  .card-body {
    padding: 0;
  }
  .report-title {
    padding: 1.5rem !important;
    margin-bottom: 0;
    border-bottom: 1px solid var(--border-color);
    .card-title {
      margin-bottom: 0px;
    }
  }
  .report-body {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }
  .min-180 {
    min-width: 210px;
    max-width: 210px;
    width: 210px;
  }
  .download {
    svg {
      cursor: pointer;
    }
    .inprogress,
    .failed {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .report-table {
    min-height: calc(100vh - 282px);
  }
  .notification-per-page {
    margin-left: 0;
    margin-right: 15px;
  }
  .search-filter {
    margin-right: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .per-page-selector {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .report-table {
    min-height: calc(100vh - 245px);
  }
  .report-page .notification-per-page{
    width: 100% !important;
    margin-right: 0 !important;
  }
  .search-display-1{
    .mobile-margin-top.no-btm-0 {
      margin-right: 0px;
      width: 100%;
    }
  }
}
.label-w-a {
  max-width: 170px;
  width: 170px;
  min-width: 170px;
  text-align: left !important;
}
.btn.btn-icon {
  padding: 0px !important;
}
.disabled-report-icon {
  pointer-events: none;
  opacity: 0.5;
}

.report-page {
  .b-table-sticky-header {
    max-height: calc(100vh - 270px) !important;
    overflow-y: auto;
    .table {
      width: calc(100% - 18px) !important;
    }
  }
}
</style>
<style lang="scss">
.b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      top: -1px !important;
    }
  }
}
.dark-layout .b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      color: #d0d2d6 !important;
    }
  }
}
</style>

<template>
  <div>
    <b-media no-body v-if="!(trip_meter === null || trip_meter === undefined)">
      <trip-odometer class="mr-75" />
      <h6 class="align-self-center my-auto">
        <b>Trip Odometer</b> :
        <span class="info-card-span">
          {{ parseInt(trip_meter) }} {{ unit }}</span
        >
      </h6>
    </b-media>
    <b-media
      no-body
      v-if="!(total_meter === null || total_meter === undefined)"
    >
      <trip-odometer />

      <h6 class="align-self-center my-auto">
        <b>{{ $t("TotalOdometer") }}</b> :
        <span class="info-card-span">
          {{ parseInt(total_meter) }} {{ unit }}</span
        >
      </h6>
    </b-media>
  </div>
</template>

<script>
import { BMedia, BMediaBody, BImg } from "bootstrap-vue";
import TripOdometer from "@/assets/images/icons/unit-icons/trip-odometer.svg";
export default {
  name: "odometer",
  components: { BMedia, BMediaBody, BImg, TripOdometer },
  props: ["trip_meter", "unit", "total_meter"],
  methods: {},

  data() {}
};
</script>

<style lang="scss" scoped>
.media {
  align-items: center !important;
  padding-top: 10px;
}
.info-card-span {
  font-weight: bold;
  color: #7367f0;
}
</style>

<template>
  <div>
    <b-modal
      id="modal-notification"
      :title="$t('ReminderNotification.Title')"
      centered
      :no-close-on-backdrop="true"
      hide-footer
    >
      <b-form-group :label="$t('ReminderNotification.FirstReminder')">
        <v-select
          v-model="reminder_config.first_reminder"
          :options="configData.firstReminderOptions"
          :reduce="(option) => option.value"
          label="label"
          :clearable="false"
          :placeholder="$t('ReminderNotification.FirstPlaceholder')"
        />
      </b-form-group>
      <b-form-group :label="$t('ReminderNotification.SecondReminder')">
        <v-select
          v-model="reminder_config.second_reminder"
          :options="configData.secondReminderOptions"
          :reduce="(option) => option.value"
          label="label"
          :clearable="false"
          :placeholder="$t('ReminderNotification.SecondPlaceholder')"
        />
      </b-form-group>

      <div class="text-center mt-2 mb-1">
        <b-button
          variant="primary"
          :disabled="!reminder_config"
          @click="setDefaultNotifications"
          v-if="
            checkAbility({
              action:
                constants.PERMISSIONS_ACTION.NOTIFICATION_PREFERENCES_SAVE,
              subject: constants.PERMISSIONS_MODEL.SERVICE_REMINDERS
            })
          "
        >
          {{ $t("ReminderNotification.SaveButton") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BFormGroup } from "bootstrap-vue";
import MaintenanceService from "@/libs/api/maintenance-service";
import AuthService from "@/libs/api/auth-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { subject } from "@casl/ability";

export default {
  components: {
    vSelect,
    BButton,
    BFormGroup
  },
  data() {
    return {
      configData: {},
      reminder_config: {
        first_reminder: 7,
        second_reminder: 1
      }
    };
  },
  mounted() {
    this.getDefaultNotifications();
    this.getConfigData();
  },
  methods: {
    async getConfigData() {
      try {
        let response = await new AuthService().getConfigData();
        if (response && response.data) {
          this.configData = response.data;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async setDefaultNotifications() {
      try {
        const response = await new MaintenanceService().setDefaultNotifications(
          {
            reminder_config: this.reminder_config
          }
        );
        if (response && response.data && response.data.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: this.$t("ReminderNotification.SetSuccessfully"),
              icon: "InfoIcon",
              variant: "success"
            }
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                response.error.title ||
                this.$t("ReminderNotification.ErrorTitle"),
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (error) {
        console.error("Error setting Default Notifications:", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("ReminderNotification.ErrorTitle"),
            text: error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }

      this.$bvModal.hide("modal-notification");
    },
    async getDefaultNotifications() {
      try {
        const response =
          await new MaintenanceService().getDefaultNotifications();
        if (response && response.data && response.data.reminder_config) {
          this.reminder_config = response.data.reminder_config;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                response.error.title ||
                this.$t("ReminderNotification.ErrorTitle"),
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (error) {
        console.error("Error fetching Default Notifications:", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("ReminderNotification.ErrorTitle"),
            text: error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    showToast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          text: message,
          icon: "InfoIcon",
          variant: variant
        }
      });
    }
  }
};
</script>

<style lang="scss">
#modal-notification___BV_modal_title_ {
  font-size: 20px;
  margin-left: auto;
}
</style>

<template>
  <div class="date-range-picker unit-placeholder">
    <date-range-picker
      v-model="dateRange"
      @update="updateValues"
      :auto-apply="false"
      :disabled="!isEditable"
      :timePicker="true"
      :single-date-picker="true"
      :minDate="isMinDate ? getCurrentTime() : ''"
      :maxDate="isMaxDate ? getCurrentTime() : ''"
      :ranges="ranges"
    >
      <template #input="picker">
        <span v-if="dateRange && dateRange.startDate">
          {{
            `${picker.startDate ? `${DateTimeConvert(picker.startDate)}` : ""}`
          }}
          <!-- {{
          `${picker.endDate ? ` - ${DateTimeConvert(picker.endDate)}` : " "}`
        }} -->
        </span>
        <span v-else class="place-holder-date">{{ $t("unit.SelectDate") }} </span>
      </template>
      <!--    date slot-->
      <template #date="data">
        <span class="small">{{ data.date | dateCell }}</span>
      </template>
      <!--    ranges (new slot syntax) -->
      <template #ranges="ranges">
        <div class="ranges">
          <ul>
            <li
              v-for="(range, name) in ranges.ranges"
              :key="name"
              @click="ranges.clickRange(range)"
            >
              <b>{{ name }}</b>
            </li>
          </ul>
        </div>
      </template>
      <!--    footer slot-->
    </date-range-picker>
  </div>
</template>

<script>
import constants from "@/utils/constants";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: { DateRangePicker },
  data() {
    return {
      dateRange: {
        startDate: this.getCurrentTime(),
        endDate: this.getCurrentTime()
      },
      ranges: this.getRanges()
    };
  },

  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    }
    // date(val) {
    //   return val ? val.toLocaleString() : "";
    // }
  },
  props: [
    "name",
    "desc",
    "label",
    "data_type",
    "component",
    "value",
    "isEditable",
    "isRequired",
    "isMinDate",
    "isMaxDate"
  ],
  watch: {
    value() {
      this.dateSets();
    }
  },
  mounted() {
    this.dateSets();
  },
  methods: {
    updateValues(e) {
      this.dateRange.endDate = this.dateRange.endDate;
      this.$emit("input", `${this.dateRange.startDate}`);
    },
    getRanges() {
      if (this.isMinDate) {
        return false;
      }
      return this.getCustomRanges();
    },

    DateTimeConvert(date, format) {
      return this.formattedDateTimeWithoutTimezone(
        date,
        constants.DATE_TIME_FORMAT
      );
    },
    dateSets() {
      if (this.value && this.value.startDate && this.value.endDate) {
        this.dateRange = {
          startDate: this.value.startDate,
          endDate: this.value.endDate
        };
      } else if (
        this.value &&
        typeof this.value == "String" &&
        this.value.includes("to")
      ) {
        const dates = this.value.split(" to ");
        this.dateRange = {
          startDate: dates[0],
          endDate: dates[1]
        };
        // this.dateRange.endDate = this.dateRange.endDate.toLocaleString();
      } else if (this.value) {
        this.dateRange = {
          startDate: this.getCurrentTime(this.value),
          endDate: this.getCurrentTime(this.value)
        };
      } else {
        this.dateRange = {
          startDate: null,
          endDate: null
        };
      }
    }
  }
};
</script>

<style lang="scss">
.daterangepicker select.hourselect {
  background: transparent !important;
  padding-left: 10px;
}
.daterangepicker select.minuteselect {
  background: transparent !important;
  padding-left: 10px;
}
.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
.dark-layout {
  .date-range-picker {
    .reportrange-text {
      border: 1px solid #404656 !important;
    }
  }
  .unit-placeholder {
    .vue-daterange-picker .form-control span {
      color: var(--white);
    }
  }
}
.date-range-picker {
  .reportrange-text {
    border: 1px solid #d8d6de !important;
  }
}
// .reportrange-text {
//   background: transparent !important;
//   padding: 9px 10px !important;
//   border: 1px solid #d8d6de !important;
//   margin-bottom: 10px;
// }
// .daterangepicker.openscenter {
//   left: 23px !important;
// }
// @media only screen and (max-width: 1024px) {
//   .daterangepicker.openscenter {
//     left: 50% !important;
//     .calendars {
//       overflow-y: auto;
//       max-height: 40vh;
//     }
//   }
//   .daterangepicker .drp-selected {
//     font-size: 9px;
//   }
// }
</style>

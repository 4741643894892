<template>
  <div class="tab-header">
    <b-card class="d-flex group-pb">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title">
          {{ $t("groups.Groups") }}
        </h4>
        <b-button
          variant="outline-primary"
          :class="!isSaveShow ? 'back-btn-clone v2-back' : 'back-btn v2-back'"
          size="sm"
          @click="redirectBackScreen"
        >
          <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
        </b-button>
      </div>
      <div>
        <div class="group-card-scroll">
          <div class="event-card-row event-card-mobile">
            <div class="event-card">
              <div
                :class="'select-event-row light-bg-primary'"
                style="cursor: pointer"
              >
                <div class="text-row">
                  <div class="group-name">
                    <GroupIcon class="group-svg mobile-icon-view mr-1" />
                    <validation-observer ref="groupRules" tag="form">
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        vid="Name"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-form-input
                          id="name"
                          name="name"
                          :state="errors.length > 0 ? false : null"
                          v-if="isEditable"
                          v-model="groupName"
                        />
                        <span v-else class="ml-2">{{ groupName }}</span>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </validation-observer>
                  </div>
                  <div class="d-flex align-items-center">
                    <feather-icon
                      v-b-tooltip.hover.left="$t('tooTip.save')"
                      v-if="isEditable"
                      icon="SaveIcon"
                      class="action-icon cursor-pointer mr-1"
                      size="18"
                      @click="updateGroupDetails"
                    />
                    <feather-icon
                      v-b-tooltip.hover.left="$t('tooTip.update')"
                      icon="EditIcon"
                      class="text-primary action-icon cursor-pointer"
                      size="18"
                      @click.stop="isEditableText"
                      v-if="$route.name == 'edit-group'"
                    />
                  </div>
                </div>
                <b-media class="align-item-center top-10">
                  <b-form-textarea
                    v-if="isEditable"
                    v-model="groupDescription"
                    ref="groupDescription"
                  />
                  <span v-else>
                    {{ groupDescription }}
                  </span>
                </b-media>
              </div>
            </div>
          </div>

          <div class="group-card-row">
            <div
              class="group-card group-card-mobile mt-1"
              v-for="count in counters"
              :key="count.entity_type"
            >
              <div
                class="card"
                :class="
                  selectedEntityType === count.entity_type
                    ? 'active-alerts '
                    : 'normal-alerts'
                "
                @click="selectEntity(count.entity_type)"
                style="cursor: pointer"
              >
                <div
                  class="card-body d-flex justify-content-between align-items-center"
                >
                  <b-avatar
                    size="68"
                    variant="light-secondary"
                    class="icon-mobile-view"
                  >
                    <GroupIcons
                      :entityType="count.entity_type"
                      class="group-svg"
                    />
                  </b-avatar>

                  <div class="truncate group-truncate">
                    <span class="group-name group-name-active">
                      {{ $t(`GroupEntity.${count.entity_type}`) }}
                    </span>
                    <h2 class="mb-25 font-weight-bolder group-name-active">
                      {{ count.count }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GroupEntityList
            class="mt-1"
            :selectedEntityType="selectedEntityType"
            :groupName="groupName"
          />
        </div>
      </div>
    </b-card>
    <b-overlay :show="show" no-wrap> </b-overlay>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BOverlay,
  BImg,
  BMedia,
  BFormInput,
  BAvatar,
  BRow,
  BCol,
  VBTooltip,
  BFormTextarea
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import RolesList from "@/views/RolesManagement/RoleList.vue";
import UtilityService from "@/libs/api/utility-service";
import constants from "@/utils/constants";
import GroupIcon from "@/assets/images/icons/group-icon.svg";
import UnitIcon from "@/assets/images/icons/group-icons/Unit.svg";
import GroupIcons from "./GroupIcons.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import DeviceList from "@/views/DeviceOnboarding/DevicesList.vue";
import GroupEntityList from "./GroupEntityList.vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BOverlay,
    BImg,
    BMedia,
    BFormInput,
    BAvatar,
    GroupIcon,
    BRow,
    BCol,
    StatisticCardHorizontal,
    UnitIcon,
    RolesList,
    GroupIcons,
    VBTooltip,
    BFormTextarea,
    DeviceList,
    GroupEntityList
  },
  data() {
    return {
      roleData: {},
      show: false,
      selectedTab: 0,
      isSaveShow: false,
      isSelectedUserList: false,
      selectedUserList: [],
      isEditable: false,
      selectedEntityType: "",
      groupName: "",
      groupDescription: "",
      counters: []
    };
  },

  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.groupRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    isEditableText() {
      this.isEditable = !this.isEditable;
    },
    selectEntity(entityType) {
      this.selectedEntityType = entityType;
    },
    formattedDescriptions(description) {
      return description ? `${this.description.slice(0, 20).trim()}...` : "";
    },
    async updateGroupDetails() {
      await this.validationForm();
      const me = this;
      me.show = true;
      try {
        const updateData = {
          label_id: me.$route.params.id,
          name: me.groupName,
          description: me.groupDescription || ""
        };
        let response = await new UtilityService().updateGroupDetails(
          updateData
        );
        me.show = false;
        if (response && response.data) {
          this.isEditable = false;
          me.$toast({
            component: ToastificationContent,
            props: {
              title: me.$t("groups.GroupUpdateSuccess"),
              text: me.$t("groups.GroupUpdateSuccessMessage"),
              icon: "InfoIcon",
              variant: "success"
            }
          });
        } else if (response && response.error && response.error.message) {
          me.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        me.show = false;
      }
    },

    redirectBackScreen(id) {
      //this.$router.push({ name: "group-list" });
      this.$router.go(-1);
    },
    async getGroupDetails() {
      const me = this;
      let response = await new UtilityService().getGroupDetails({
        label_id: me.$route.params.id,
        account_id: this.$route.query.account_id
      });

      this.$route.meta.breadcrumb = JSON.parse(
        JSON.stringify(constants.GROUP_ROUTE_META_BREADCRUMB)
      );
      if (response && response.data) {
        me.groupName = response.data.name;
        me.groupDescription = response.data.description;
        me.counters = response.data.counters;
        this.$route.meta.breadcrumb = [
          ...constants.GROUP_ROUTE_META_BREADCRUMB
        ];

        if (this.$route.name === "edit-group") {
          this.$route.meta.breadcrumb.push(
            ...[
              {
                to: {
                  name: "edit-group",
                  params: { id: response.data.label_id }
                },
                text: response.data.name
              },
              {
                text: "Breadcrumb_Edit",
                active: true
              }
            ]
          );
        }
        if (this.$route.name === "view-group") {
          this.$route.meta.breadcrumb.push(
            ...[
              {
                to: {
                  name: "view-group",
                  params: { id: response.data.label_id }
                },
                text: response.data.name
              }
            ]
          );
        }
      } else if (response && response.error && response.error.message) {
        me.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  },

  async mounted() {
    await this.getGroupDetails();
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
// .edit-role-owner {
//   .card-title {
//     min-height: 40px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }
// }
.group-pb {
}
.back-btn-clone {
  margin-left: auto;

  margin-bottom: $px_15;
}
.clone-role-btn {
  margin-left: auto;
  margin-bottom: $px_15;
  margin-right: $px_15;
}
.fill-white svg,
.fill-white path {
  fill: var(--white);
}
body {
  font-family: Arial;
  font-size: 1.3em;
  line-height: 1.6em;
}

.headline {
  font-size: 2em;
  text-align: center;
}

.slect-account-title {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
  color: $headings-color;
  margin-top: 25px;
  margin-bottom: 33px;
}
.select-event-row.active-primary {
  padding: 13px 10px;
}
.select-event-row {
  margin-bottom: 5px;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #b9b9c3;
}

.mb-8 {
  margin-bottom: 8px;
}
.group-card {
  box-shadow: 0px 4px 12px rgba(34, 41, 47, 0.1);
  border-radius: 8px;
  margin-right: 1%;
  width: 19%;
  .card {
    margin-bottom: 0px;
    border: 1px solid transparent;
  }
}
.group-card-column .card-body {
  flex-direction: row-reverse !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #6e6b7b;
}
.group-avtar {
  display: flex;
  justify-content: start;
  width: 30%;
}
.group-truncate {
  margin: auto;
  text-align: center;
}
.group-card-row {
  display: flex;
  flex-wrap: wrap;
}
.group-name {
  font-weight: 600;
  display: flex;
  align-items: center;
  svg {
    width: 25px;
  }
}
.svg.verify-svg,
.verify-svg path {
  fill: var(--primary);
  width: 20px;
  height: 20px;
}
@media only screen and (max-width: 1100px) {
  .role-btn {
    margin-right: 0px !important;
  }
  .back-btn-clone {
    margin-right: 0px !important;
  }
  .event-card-mobile {
    .event-card {
      min-height: 70px !important;
      .select-event-row {
        margin-bottom: 0px !important;
      }
    }
  }
  .group-card-row {
    .group-card-mobile {
      width: calc(50% - 5px);
      margin-top: 5px !important;
      margin-right: 2.5px !important;
      margin-left: 2.5px !important;
    }
    .icon-mobile-view {
      width: 48px !important;
      height: 48px !important;
      .group-svg {
        transform: scale(0.7);
        -webkit-transform: scale(0.7); /* Safari & Chrome */
        -moz-transform: scale(0.7); /* Firefox */
        -ms-transform: scale(0.7); /* Internet Explorer */
        -o-transform: scale(0.7);
      }
    }
  }
}
</style>

<template>
  <div class="alerts-search-comp">
    <b-card class="alert-search position-relative main-card-padding">
      <div class="m-2-br-alert-bottm">
        <div class="adduser-btn-row">
          <h4 class="card-title">
            {{ $t("operators.Operators") }}
          </h4>
          <div class="filter-btn-outer-new d-flex">
            <b-button
              v-b-tooltip.hover.v-primary
              :title="$t('operators.AddOperators')"
              variant="primary"
              class="btn-icon desktop-hidden mr-1"
              @click="addOperator"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.OPERATORS
                }) && !isGroupEntity
              "
            >
              <feather-icon icon="PlusIcon" size="12" />
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              class="desktop-hidden"
              @click="showFiltermenu = !showFiltermenu"
            >
              <feather-icon icon="FilterIcon" size="15" />
            </b-button>
          </div>
          <div class="d-flex flex-wrap filter-card" v-if="showFiltermenu">
            <div class="alert-per-page" v-if="!isGroupEntity">
              <div class="addXIcon">
                <feather-icon
                  class="sizeIcon"
                  @click="showFiltermenu = !showFiltermenu"
                  icon="XIcon"
                  size="15"
                />
              </div>

              <label class="search-label mt-1">{{
                $t("Notification.Entries")
              }}</label>
              <v-select
                :clearable="false"
                id="vue-select"
                class="per-page-selector d-inline-block fix-83"
                v-model="perPage"
                :options="option"
              />
            </div>
            <search
              v-if="!isGroupEntity"
              style="float: left"
              class="search-comp no-btm-0"
              v-model="searchFiled"
              :searchColumn="searchColumn"
              :operator="operator"
            ></search>

            <b-button
              v-b-tooltip.hover.v-primary
              :title="$t('operators.AddOperators')"
              variant="primary"
              class="mobile-hidden"
              @click="addOperator"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.OPERATORS
                }) && !isGroupEntity
              "
            >
              <feather-icon icon="PlusIcon" size="12" />
              <span class="mobile-hidden">{{
                $t("operators.AddOperators")
              }}</span>
            </b-button>
          </div>
        </div>
      </div>

      <OperatorList
        :searchFiled="searchFiled"
        :perPage="perPage"
        :groupName="groupName"
      ></OperatorList>
    </b-card>
  </div>
</template>

<script>
import { BCard, BAlert, BButton, VBTooltip } from "bootstrap-vue";
import OperatorList from "@/views/Operators/OperatorList.vue";
import constants from "@/utils/constants";
import Search from "../Common/search.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BAlert,
    BButton,
    VBTooltip,
    Search,
    vSelect,
    OperatorList
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      searchColumn: ["name", "code", "labels", "username", "email"],
      searchFiled: {},
      operator: "ilike",
      perPage: 10,
      option: ["10", "20", "30"],
      showFiltermenu: false
    };
  },
  props: ["isGroupEntity", "groupName"],
  watch: {},
  mounted() {
    const { perPage } = this.$route.query;

    if (perPage) {
      this.perPage = parseInt(perPage);
    }
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },
    addOperator() {
      this.$router.push({ name: "create-operator" });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.alert-search {
  .alert-per-page {
    margin-left: auto;
    margin-right: 15px;
  }
  .search-filter {
    margin-left: 0;
  }
  .operator-table-width {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .search-filter {
    margin-right: 15px;
  }
}
</style>

import APIService from "./api";

/** This class will include all services defined in unit-service */
export default class DataService extends APIService {
  service = "data-service";
  async updateClippingTime(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateClippingTime" },
      data,
      options
    );
  }
  async fetchAllUnitsWithData(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "fetchAllUnitsWithData" },
      data,
      options
    );
  }
  async getRecordedVideos(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getRecordedVideos" },
      data,
      options
    );
  }
  async getLiveVideoStream(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getLiveVideoStream" },
      data,
      options
    );
  }
  async createLiveVideoStream(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createLiveVideoStream" },
      data,
      options
    );
  }
  async stopLiveVideoStream(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "stopLiveVideoStream" },
      data,
      options
    );
  }
  async getVideoStreamById(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getVideoStreamById" },
      data,
      options
    );
  }
  async videoStreamStarted(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "videoStreamStarted" },
      data,
      options
    );
  }
  async videoStreamFinished(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "videoStreamFinished" },
      data,
      options
    );
  }
  async fetchUnitHistoryData(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "fetchUnitHistoryData" },
      data,
      options
    );
  }

  async fetchUnitPosition(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "fetchUnitPosition" },
      data,
      options
    );
  }

  async fetchUnitMilestone(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "fetchUnitMilestone" },
      data,
      options
    );
  }

  async getUnitDataWithSensors(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitDataWithSensors" },
      data,
      options
    );
  }

  async unitMilestones(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "unitMilestonesV2" },
      data,
      options
    );
  }

  async getUnitCoords(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitCoordsV2" },
      data,
      options
    );
  }
  async getUnitMessage(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitMessage" },
      data,
      options
    );
  }
}

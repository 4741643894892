var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"report-page"},[_c('div',{staticClass:"report-title d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("ReportsData.ReportsTitle"))+" ")]),_c('div',{staticClass:"filter-btn-outer-new"},[(
            _vm.checkAbility({
              action: _vm.constants.PERMISSIONS_ACTION.ADD,
              subject: _vm.constants.PERMISSIONS_MODEL.REPORTS
            }) && !_vm.isGroupEntity
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"mr-1 desktop-hidden",attrs:{"title":_vm.$t('ReportsData.CreateReport'),"variant":"primary","to":{ name: 'select-report' }}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"12"}})],1):_vm._e(),_c('b-button',{staticClass:"desktop-hidden",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.showFiltermenu = !_vm.showFiltermenu}}},[_c('feather-icon',{attrs:{"icon":"FilterIcon","size":"15"}})],1)],1),(_vm.showFiltermenu)?_c('div',{staticClass:"d-flex flex-wrap filter-card search-display-1"},[_c('div',{staticClass:"notification-per-page"},[_c('div',{staticClass:"addXIcon"},[_c('feather-icon',{staticClass:"sizeIcon",attrs:{"icon":"XIcon","size":"15"},on:{"click":function($event){_vm.showFiltermenu = !_vm.showFiltermenu}}})],1),_c('label',{staticClass:"search-label mt-1"},[_vm._v(_vm._s(_vm.$t("Notification.Entries")))]),_c('v-select',{staticClass:"per-page-selector d-inline-block fix-83",attrs:{"clearable":false,"id":"vue-select","options":_vm.option},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),(!_vm.isGroupEntity)?_c('search',{staticClass:"mobile-margin-top no-btm-0",staticStyle:{"float":"left"},attrs:{"searchColumn":_vm.searchColumn,"operator":_vm.operator},model:{value:(_vm.searchFiled),callback:function ($$v) {_vm.searchFiled=$$v},expression:"searchFiled"}}):_vm._e(),(
            _vm.checkAbility({
              action: _vm.constants.PERMISSIONS_ACTION.ADD,
              subject: _vm.constants.PERMISSIONS_MODEL.REPORTS
            }) && !_vm.isGroupEntity
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"mobile-hidden",attrs:{"title":_vm.$t('ReportsData.CreateReport'),"variant":"primary","to":{ name: 'select-report' }}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"12"}}),_c('span',{staticClass:"mobile-hidden"},[_vm._v(_vm._s(_vm.$t("ReportsData.CreateReport")))])],1):_vm._e()],1):_vm._e()]),(_vm.show)?_c('div',{staticClass:"table-responsive"},[(_vm.show)?_c('b-skeleton-table',{staticClass:"device-list2",attrs:{"rows":this.perPage || 10,"columns":7,"table-props":{ bordered: true, striped: true }}}):_vm._e()],1):_vm._e(),(!_vm.show)?_c('b-table',{staticClass:"position-relative report-table dre-table",attrs:{"responsive":"","show-empty":"","sticky-header":"","align-v":"end","items":_vm.items,"fields":_vm.tableColumns,"empty-text":_vm.$t('NoMatchingRecordsFound'),"hover":_vm.$route.name === 'notification-create'},scopedSlots:_vm._u([{key:"cell(report_name)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.report_name)+" ")]}},{key:"cell(report_type)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.processor)+" ")]}},{key:"cell(generated)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.socketId == row.item.id ? _vm.socketTime : _vm.DateTimeConvert(row.item.mtime))+" ")]}},{key:"cell(labels)",fn:function(row){return [_c('div',{staticClass:"parent",attrs:{"id":"parent"}},[_c('DynamicBadge',{staticClass:"try",attrs:{"isList":true,"entityType":'REPORT',"tagsData":row.item.labels,"showTextInBadge":Number(7),"event":Object.assign({}, row.item, {id: row.item.id}),"maxTagShow":Number(_vm.defaultBadgeShow),"listApi":_vm.debounceGetAllReports}}),_c('b-badge',{staticClass:"badge-cust",attrs:{"variant":_vm.resolveFilterBadgeColor(row.item.status)}})],1)]}},{key:"cell(status)",fn:function(row){return [(
            row.item.status === 'PROCESSING' &&
            row.item.progress < 100 &&
            row.item.recent_time <= 5
          )?_c('b-progress',{staticClass:"progress-bar-success",attrs:{"value":row.item.progress,"max":"100","variant":"success"}}):_vm._e(),(
            row.item.status === 'FAILED' ||
            (row.item.recent_time > 5 && row.item.status === 'PROCESSING')
          )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
            (row.item.error != 0 && row.item.error) || ''
          ),expression:"\n            (row.item.error != 0 && row.item.error) || ''\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"text-danger",attrs:{"icon":"AlertTriangleIcon","size":"20"}}):_vm._e(),(
            row.item.status === 'COMPLETED' ||
            row.item.status >= 100 ||
            (row.item.formats && row.item.formats.length)
          )?_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"CheckCircleIcon","size":"20"}}):_vm._e()]}},{key:"cell(formats)",fn:function(row){return [_c('span',{staticClass:"d-flex"},_vm._l((row.item.formats),function(item){return _c('div',{key:item.id},[(item.extension === 'EXCEL' && item.file_id)?_c('ExlReportIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],class:!item.file_id
                  ? ("disabled-report-icon report-icon " + (row.item.status))
                  : ("cursor-pointer report-icon " + (row.item.status)),attrs:{"title":_vm.$t('ReportsData.EXL')},on:{"click":function($event){return _vm.downloadReport(item.file_id)}}}):_vm._e(),(item.extension === 'PDF')?_c('PdfReportIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],class:!item.file_id
                  ? ("disabled-report-icon report-icon " + (row.item.status))
                  : ("cursor-pointer report-icon " + (row.item.status)),attrs:{"title":_vm.$t('ReportsData.PDF')},on:{"click":function($event){return _vm.downloadReport(item.file_id)}}}):_vm._e(),(item.extension === 'DOC' && item.file_id)?_c('DocReportIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],class:!item.file_id
                  ? ("disabled-report-icon report-icon " + (row.item.status))
                  : ("cursor-pointer report-icon " + (row.item.status)),attrs:{"title":_vm.$t('ReportsData.DOC')},on:{"click":function($event){return _vm.downloadReport(item.file_id)}}}):_vm._e(),(item.extension === 'CHR' && item.file_id)?_c('ChartReportIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],class:!item.file_id
                  ? ("disabled-report-icon report-icon " + (row.item.status))
                  : ("cursor-pointer report-icon " + (row.item.status)),attrs:{"title":_vm.$t('ReportsData.CHAT')},on:{"click":function($event){return _vm.downloadReport(item.file_id)}}}):_vm._e(),(item.extension === 'VID' && item.file_id)?_c('VideoReportIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"report-icon",class:!item.file_id
                  ? ("disabled-report-icon report-icon " + (row.item.status))
                  : ("cursor-pointer report-icon " + (row.item.status)),attrs:{"title":_vm.$t('ReportsData.VIDEO')},on:{"click":function($event){return _vm.downloadReport(item.file_id)}}}):_vm._e()],1)}),0)]}},{key:"cell(ACTIONS)",fn:function(row){return [_c('div',{staticClass:"text-left min-80 d-flex justify-content-end"},[_c('span',[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
                  subject: _vm.constants.PERMISSIONS_MODEL.REPORTS
                }) && row.item.sensors
              )?_c('b-link',{attrs:{"to":{
                name: 'edit-report',
                params: {
                  id: row.item.id
                }
              }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooTip.update')),expression:"$t('tooTip.update')",modifiers:{"hover":true,"top":true}}],staticClass:"text-primary action-icon cursor-pointer",attrs:{"icon":"EditIcon","size":"18"}})],1):_vm._e()],1),_c('span',[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.READ,
                  subject: _vm.constants.PERMISSIONS_MODEL.REPORTS
                }) && row.item.sensors
              )?_c('b-link',{attrs:{"to":{
                name: 'view-report',
                params: { id: row.item.id }
              }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooTip.view')),expression:"$t('tooTip.view')",modifiers:{"hover":true,"top":true}}],staticClass:"text-primary action-icon cursor-pointe",attrs:{"icon":"EyeIcon","size":"18"}})],1):_vm._e()],1),_c('span',[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.DELETE,
                  subject: _vm.constants.PERMISSIONS_MODEL.REPORTS
                })
              )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooTip.delete')),expression:"$t('tooTip.delete')",modifiers:{"hover":true,"top":true}},{name:"b-modal",rawName:"v-b-modal.modal-sm-remove",modifiers:{"modal-sm-remove":true}}],staticClass:"text-danger action-icon",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteReport(row.item)}}}):_vm._e()],1)])]}}],null,false,304614109)}):_vm._e(),_c('div',{staticClass:"mx-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[(_vm.totalReport > 0)?_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalReport,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item","no-paging-nav":false,"active-class":_vm.activeClass},on:{"input":_vm.handlePageChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],1)],1),_c('AreYouSureModal',{attrs:{"data":_vm.deleteData,"status":_vm.status,"onClose":_vm.onClose,"removedUser":_vm.removeReport}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
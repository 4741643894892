var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-width-110"},[(
      _vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.READ,
        subject: _vm.constants.PERMISSIONS_MODEL.LABELS
      })
    )?_c('b-media',{staticClass:"align-item-center event-Tag dynamic-event"},[_vm._l((_vm.tags),function(tag,index){return _c('div',{key:index},[(typeof tag === 'string' ? tag : tag.name || '')?_c('span',[(index < _vm.maxTagShow)?_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"padding-right":"3px"}},[_c('b-badge',{attrs:{"variant":"primary","pill":""}},[_vm._v(" "+_vm._s(_vm.formattedDescriptions(tag.name || tag, _vm.showTextInBadge))+" ")])],1):_vm._e()]):_vm._e()])}),(
        (_vm.tags && _vm.tags.length > _vm.maxTagShow) ||
        (_vm.tags.length <= _vm.maxTagShow &&
          _vm.checkAbility({
            action: _vm.constants.PERMISSIONS_ACTION.ADD,
            subject: _vm.constants.PERMISSIONS_MODEL.LABELS
          })) ||
        (_vm.tags.length == 0 &&
          _vm.checkAbility({
            action: _vm.constants.PERMISSIONS_ACTION.ADD,
            subject: _vm.constants.PERMISSIONS_MODEL.LABELS
          }))
      )?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
        _vm.checkAbility({
          action: _vm.constants.PERMISSIONS_ACTION.ADD,
          subject: _vm.constants.PERMISSIONS_MODEL.LABELS
        })
          ? _vm.$t('addLabels')
          : _vm.$t('ViewLabels')
      ),expression:"\n        checkAbility({\n          action: constants.PERMISSIONS_ACTION.ADD,\n          subject: constants.PERMISSIONS_MODEL.LABELS\n        })\n          ? $t('addLabels')\n          : $t('ViewLabels')\n      ",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer",attrs:{"variant":"primary","pill":"","id":_vm.event.id + "id","tabindex":"0"},on:{"click":_vm.onEditableTags}},[_vm._v(_vm._s(_vm.tags.length > _vm.maxTagShow ? Number(_vm.tags.length) - _vm.maxTagShow + "+" : "+")+" ")]):_vm._e(),_c('b-popover',{attrs:{"target":_vm.event.id + "id","triggers":"click","placement":"lefttop","custom-class":"pop-over-primary","show":_vm.showPopover},on:{"update:show":function($event){_vm.showPopover=$event}}},[_c('div',{staticClass:"d-flex align-items-start"},[(!_vm.isEditable)?_c('div',{staticClass:"d-flex-tool label-batch",style:(_vm.tags.length ? ("" + ('width:' + _vm.tags.length * 30)) : 'width:300px')},_vm._l((_vm.tags),function(tag,index){return _c('div',{key:index},[(typeof tag === 'string' ? tag : tag.name || '')?_c('span',[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"padding":"3px"}},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(tag.name)+" ")])],1)]):_vm._e()])}),0):_vm._e(),(_vm.isEditable)?_c('div',{staticClass:"w-100"},[(_vm.isEditable)?_c('TagEntity',{attrs:{"isList":_vm.isList,"event":_vm.event,"entityType":_vm.entityType,"isEditableTags":_vm.isEditableTags,"listApi":_vm.listApi},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}}):_vm._e()],1):_vm._e(),(
            _vm.checkAbility({
              action: _vm.constants.PERMISSIONS_ACTION.ADD,
              subject: _vm.constants.PERMISSIONS_MODEL.LABELS
            }) ||
            _vm.checkAbility({
              action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
              subject: _vm.constants.PERMISSIONS_MODEL.LABELS
            })
          )?_c('div',{staticClass:"badge-icon"},[(!_vm.showAllTag && !_vm.isEditable)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('tooTip.update')),expression:"$t('tooTip.update')",modifiers:{"hover":true,"left":true}}],staticClass:"action-icon cursor-pointer right-0",attrs:{"icon":"EditIcon","size":"18"},on:{"click":_vm.isEditableTags}}):_vm._e()],1):_vm._e(),_c('feather-icon',{staticClass:"action-icon cursor-pointer right-0 close-icon-circle",attrs:{"icon":"XCircleIcon","size":"18"},on:{"click":_vm.closePopOver}})],1)])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
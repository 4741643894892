<template>
  <div class="route-main">
    <div class="geo-search">
      <div class="input-group-merge">
        <search
          v-model="searchFiled"
          :searchColumn="searchColumn"
          :hideLabel="true"
          :operator="operator"
        ></search>
      </div>
      <b-button
        @click="redirectAddRoute(null)"
        variant="primary"
        class="btn-icon"
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.ADD,
            subject: constants.PERMISSIONS_MODEL.ROUTES
          }) && !this.groupName
        "
      >
        <feather-icon icon="PlusIcon" />
        <span class="d-none d-sm-inline ml-1">{{ $t("route.AddNew") }}</span>
      </b-button>
    </div>
    <div
      class="search-outer padding-btm-0 mb-1 mt-1"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.CONSUMER_ACCOUNT_READ,
          subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
        }) ||
        checkAbility({
          action: constants.PERMISSIONS_ACTION.ACCOUNT_READ,
          subject: constants.PERMISSIONS_MODEL.ACCOUNT
        })
      "
    >
      <AccountSearch
        all="true"
        class="unit-search-v2"
        v-model="selectedAccount"
      />
    </div>
    <div class="table-responsive mt-1" v-if="showLoading">
      <b-skeleton-table
        :rows="10"
        :columns="2"
        :table-props="{ bordered: true, striped: true }"
      />
    </div>
    <div v-else>
      <div class="geo-outer">
        <div class="d-flex justify-content-between align-items-center mb-1">
          <div class="">
            <!-- dfddf {{ unitsData }} -->
            <b-form-checkbox
              v-if="totalRoute && !groupName"
              style="min-width: 10px; margin-left: 4px"
              :title="$t('route.SelectAll')"
              v-model="isAllSelected"
              @change="clearAll"
              ><span class="ml-1">{{
                $t("route.SelectAll")
              }}</span></b-form-checkbox
            >
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <b-form-checkbox
              v-if="!groupName"
              v-model="isAllowChild"
              v-b-tooltip.hover="{
                title: isAllowChild
                  ? $t('tooTip.HideInheritedItems')
                  : $t('tooTip.ShowInheritedItems'),
                customClass: 'device-tooltip-class '
              }"
              switch
              class="mx-h"
            ></b-form-checkbox>

            <feather-icon
              @click="deleteSelectedRoute()"
              v-b-tooltip.hover="{
                title: $t('tooTip.DeleteSelected'),
                customClass: 'device-tooltip-class'
              }"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.DELETE,
                  subject: constants.PERMISSIONS_MODEL.ROUTES
                }) &&
                (isAllSelected || selectedRoutesIds.length > 1)
              "
              icon="Trash2Icon"
              class="text-danger mrl_select"
              size="22"
            ></feather-icon>
          </div>
        </div>

        <b-list-group v-if="totalRoute !== 0 && !showLoading">
          <virtual-list
            style="overflow-y: auto"
            :style="{ height: 'calc(100vh - 270px)' }"
            :data-key="'id'"
            :data-sources="items"
            :estimate-size="10"
            :dataComponent="{}"
            v-on:tobottom="handleScroll"
          >
            <template #item="{ item: item }">
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <div class="check-outer">
                  <b-form-checkbox
                    v-if="!groupName"
                    :style="groupName ? { 'pointer-events': 'none' } : ''"
                    v-model="item.selected"
                    @change="clickSelectedRoute(item, $event)"
                  >
                    <div class="d-flex align-items-center">
                      <div class="poly-outer">
                        <RoutImg />
                      </div>
                      <span>{{ item.name }}</span>
                    </div>
                  </b-form-checkbox>
                  <div v-if="groupName">
                    <div class="d-flex align-items-center">
                      <div class="poly-outer">
                        <RoutImg />
                      </div>
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="icon-block">
                  <b-link
                    v-if="
                      checkAbility({
                        action: constants.PERMISSIONS_ACTION.READ,
                        subject: constants.PERMISSIONS_MODEL.ROUTES
                      })
                    "
                    :to="{
                      name: 'route-view',
                      params: { id: item.id },
                      query: { account_id: selectedAccount }
                    }"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="text-primary mrl"
                      size="18"
                      v-b-tooltip.hover="{
                        title: $t('tooTip.view'),
                        customClass: 'device-tooltip-class'
                      }"
                    ></feather-icon>
                  </b-link>
                  <b-link
                    v-if="
                      item.is_editable == true &&
                      checkAbility({
                        action: constants.PERMISSIONS_ACTION.UPDATE,
                        subject: constants.PERMISSIONS_MODEL.ROUTES
                      })
                    "
                    :to="{
                      name: 'route-edit',
                      params: { id: item.id },
                      query: { account_id: selectedAccount }
                    }"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="text-primary mrl"
                      size="18"
                      v-b-tooltip.hover="{
                        title: $t('tooTip.update'),
                        customClass: 'device-tooltip-class'
                      }"
                    ></feather-icon>
                  </b-link>
                  <b-link
                    @click="redirectAddRoute(item.id)"
                    v-if="
                      checkAbility({
                        action: constants.PERMISSIONS_ACTION.ADD,
                        subject: constants.PERMISSIONS_MODEL.ROUTES
                      })
                    "
                  >
                    <feather-icon
                      v-b-tooltip.hover="{
                        title: $t('tooTip.clone'),
                        customClass: 'device-tooltip-class'
                      }"
                      icon="CopyIcon"
                      class="text-primary mrl"
                      size="18"
                    ></feather-icon
                  ></b-link>
                  <b-link @click="deleteRoute(item)">
                    <feather-icon
                      v-b-tooltip.hover="{
                        title: $t('tooTip.delete'),
                        customClass: 'device-tooltip-class'
                      }"
                      v-if="
                        item.is_editable == true &&
                        checkAbility({
                          action: constants.PERMISSIONS_ACTION.DELETE,
                          subject: constants.PERMISSIONS_MODEL.ROUTES
                        })
                      "
                      icon="Trash2Icon"
                      class="text-danger mrl"
                      size="18"
                    ></feather-icon
                  ></b-link>
                </div>
              </b-list-group-item>
            </template>
          </virtual-list>
        </b-list-group>
        <div v-if="totalRoute === 0 && !showLoading">
          <b-jumbotron :lead="$t('NoMatchingRecordsFound')"> </b-jumbotron>
        </div>
      </div>
    </div>
    <AreYouSureModal
      :data="deleteData"
      :onClose="onClose"
      :removedUser="removeRoute"
      :customData="customData"
    />
  </div>
</template>

<script>
import {
  BJumbotron,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormCheckbox,
  BAvatar,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BSkeletonTable,
  BLink,
  VBTooltip,
  BFormCheckboxGroup
} from "bootstrap-vue";
import RoutImg from "@/assets/images/rout.svg";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from "@/EventBus";
import Loader from "@/layouts/components/Loader.vue";
import store from "@/store";
import VirtualList from "vue-virtual-scroll-list";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import UtilityService from "@/libs/api/utility-service";
import Search from "@/views/Common/search.vue";
import AccountSearch from "../Unit/Unit-V2/AccountSearch.vue";
export default {
  components: {
    Loader,
    BJumbotron,
    DynamicBadge,
    AreYouSureModal,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormCheckbox,
    BAvatar,
    BFormCheckboxGroup,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BSkeletonTable,
    VirtualList,
    BLink,
    Search,
    RoutImg,
    AccountSearch
  },
  data() {
    return {
      maxHeight: 0,
      items: [],
      totalRoute: 0,

      searchColumn: ["name"],
      searchFiled: {},
      operator: "ilike",
      selected: "10",
      option: ["10", "20", "30"],
      page: 1,
      page_size: this.page_size || parseInt(window.innerHeight / 57),
      selectedAccount:
        this.$route.query.account_id || localStorage.getItem("USER_ACCOUNT_ID"),
      showFilter: false,
      isAllSelected: false,
      selectedCheckbox: [],
      currentPage: 1,

      unitsData: [],
      isReload: true,
      deleteData: null,
      customData: null,
      selectedRoutesIds: [],
      filter: {},
      unitFilter: {},

      showLoading: "false",
      isAllowChild: true,
      currentUnit: null
    };
  },
  mounted() {
    // EventBus.$emit("geofence-get-shape", {
    //   isAllSelected: true
    // });
    this.debounceGetAllRoutes();
    console.log(window.clientHeight);
    setTimeout(() => {
      this.setMaxHeight1();
    }, 1000);

    window.addEventListener("resize", this.setMaxHeight1);
  },
  watch: {
    searchFiled(val) {
      this.filter = val;
      this.currentPage = 1;
      this.debounceGetAllRoutes();
    },
    isAllowChild() {
      this.currentPage = 1;
      this.debounceGetAllRoutes();
    },
    selectedAccount(val) {
      this.currentPage = 1;
      this.debounceGetAllRoutes();
    }
  },
  props: ["geofenceControl", "groupName"],
  beforeDestroy() {
    window.removeEventListener("resize", this.setMaxHeight1);
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  methods: {
    setMaxHeight1() {
      if (this.$refs.dynamicHeightDiv1) {
        const windowHeight = window.innerHeight;
        const offset = 250; // Example offset value
        this.maxHeight = windowHeight - offset;
        this.$refs.dynamicHeightDiv1.style.height = `${this.maxHeight}px`;
      }
    },
    handleScroll() {
      if (this.totalRoute > this.currentPage * this.page_size) {
        const me = this;
        me.currentPage++;
        me.getAllRoutes(true);
      }
    },
    redirectAddRoute(id) {
      let query = {};
      if (id) {
        query.id = id;
        query.account_id = this.selectedAccount;
      }
      this.$router.push({ name: "route-create", query });
    },

    clickSelectedRoute(item, flag) {
      if (flag) {
        item.checkPoints.forEach(async (checkPoint) => {
          if (checkPoint && checkPoint.type) {
            if (checkPoint.type === "unit") {
              this.addUnitMarkerLayer(checkPoint.data, item);
            } else if (checkPoint.type === "geofence") {
              this.addGeoFenceLayer(checkPoint.data, item);
            } else if (checkPoint.type === "point") {
              this.addUnitMarkerLayer(checkPoint.data, item);
            }
          }
        });
        setTimeout(() => {
          this.geofenceControl.createSinglePolyline({
            id: item.id,
            color: this.getRandomColor()
          });
        }, 1000);
        const isAllSelected = this.items.find((e) => !e.selected);
        if (!isAllSelected || !isAllSelected.id) {
          this.isAllSelected = true;
        }
        if (item.is_editable) {
          this.selectedRoutesIds.push(item.id);
        }
      } else {
        this.geofenceControl.removeLayerByRouteId(item.id);
        this.isAllSelected = false;
        this.selectedRoutesIds = this.selectedRoutesIds.filter(
          (e) => e !== item.id
        );
      }
      this.items = this.items.map((e) => {
        if (e.id === item.id) {
          e.selected = flag;
        }
        return e;
      });
    },
    addGeoFenceLayer(data, item) {
      this.geofenceControl.addLayer({
        type: data.boundary.type
          ? data.boundary.type.toLowerCase()
          : data.boundary.type,
        coordinates: data.boundary.coordinates,
        id: data.id,

        color: data.color,
        radius: data.boundary.radius,
        route_id: item.id
      });
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    addUnitMarkerLayer(data, item) {
      this.geofenceControl.addLayer({
        type: "marker",
        coordinates: data.boundary.coordinates,
        id: data.id || Math.floor(Math.random() * 10000000) + 1,
        icon:
          data.icon || `${this.getCurrentPath()}/app/images/marker-icon.png`,
        angle: data.angle || 0,
        route_id: item.id
      });
    },
    async getAllRoutes(isScroll) {
      try {
        this.showLoading = true;
        this.isReload = false;
        const me = this;
        const requestData = {
          page: parseInt(this.currentPage),
          page_size: this.page_size,
          is_allow_child: this.isAllowChild,
          account_id: this.selectedAccount
        };
        if (this.groupName) {
          requestData.label = this.groupName;
        }

        requestData.filters =
          this.filter && Array.isArray(this.filter)
            ? this.filter
            : this.filter && this.filter.value
            ? [this.filter]
            : [];

        if (this.groupName) {
          requestData.label = this.groupName;
        }

        let response = await new UtilityService().getAllRoutes(requestData);
        this.showLoading = false;
        this.isReload = true;
        if (response && response.data) {
          const routeItem = response.data.list || [];

          routeItem.forEach((element) => {
            if (this.isAllSelected) {
              element.selected = true;
              this.clickSelectedRoute(element, true);
            } else {
              element.selected = false;
            }
          });

          this.items = isScroll ? [...this.items, ...routeItem] : routeItem;
          setTimeout(() => {
            this.setMaxHeight1();
          }, 200);

          this.totalRoute = response.data.pagination.total_records;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },
    debounceGetAllRoutes() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllRoutes();
      }, 300); // Adjust the delay as necessary
    },
    updateUrl(e) {
      this.$router
        .push({
          query: { ...this.$route.query, [e.key]: e.value }
        })
        .catch(e) > {};
    },

    onClose(isReload) {
      if (isReload) {
        this.debounceGetAllRoutes();
      }

      this.$bvModal.hide("modal-sm-remove");
      this.deleteData = null;
      this.customData = null;
    },
    clearAll() {
      if (!this.isAllSelected) {
        this.items.forEach((e) => {
          this.clickSelectedRoute(e, false);
        });
      } else {
        this.items.forEach((e) => {
          this.clickSelectedRoute(e, true);
        });
      }
    },
    async removeRoute(props) {
      try {
        this.showLoading = true;
        let req = {};
        if (this.customData && this.customData.msgTxt) {
          if (this.isAllSelected) {
            req.isAllSelected = true;
          } else {
            req.route_ids = this.selectedRoutesIds;
          }
        } else {
          req.route_ids = [props.id];
        }
        req.account_id = this.$route.query.account_id;
        let response = await new UtilityService().deleteRoute(req);
        this.showLoading = false;
        if (response && response.data) {
          if (this.customData && this.customData.msgTxt) {
            if (this.isAllSelected) {
              this.geofenceControl.removeAllLayers();
              this.isAllSelected = false;
            } else {
              for (let i = 0; i < this.selectedRoutesIds.length; i++) {
                this.geofenceControl.removeLayerById(this.selectedRoutesIds[i]);
              }
            }
            this.selectedRoutesIds = [];
          } else {
            this.selectedRoutesIds = this.selectedRoutesIds.filter(
              (e) => e !== props.id
            );
          }
          this.onClose(true);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("route.DeletedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.geofenceControl.removeLayerByRouteId(props.id);
          this.debounceGetAllRoutes();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },

    deleteRoute(props) {
      this.$bvModal.show("modal-sm-remove");
      this.deleteData = {
        name: props.name,
        id: props.id
      };
      this.customData = null;
    },
    deleteSelectedRoute() {
      this.$bvModal.show("modal-sm-remove");
      this.deleteData = {
        id: ""
      };
      this.customData = {
        msgTxt: this.$t("route.AreYouSureYouWantToDeleteAllSelectedRoutes")
      };
    },

    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.debounceGetAllRoutes();
    }
  }
};
</script>
<style lang="scss">
.mx-h {
  .custom-control-label::before {
    max-height: 1.55rem !important;
  }
  .custom-control-label::after {
    max-height: 1.55rem !important;
  }
}
</style>
<style lang="scss" scoped>
.geo-outer {
  padding-top: 15px;
  overflow-y: auto;
  .list-group-item {
    border: none;
    border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }
  .icon-block {
    display: flex;
  }
  .poly-outer {
    height: 35px;
    width: 35px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    margin-left: 10px;
    margin-right: 10px;
  }
  .mrl {
    cursor: pointer;
    margin-left: 7px;
    margin-right: 7px;
  }
  .mrl_select {
    cursor: pointer;
    margin-left: 7px;
    margin-right: 10px;
    margin-bottom: 4px;
  }
}
.pagination-outer-1 {
  text-align: center;
  display: flex;
  justify-content: center;
}
.geo-search {
  display: flex;
  padding: 0 4px;
  .input-group-merge {
    width: calc(100% - 138px);
    margin-right: 15px;
  }
  .btn-icon {
    width: 120px;
    padding: 0 10px;
  }
}
.dark-layout .jumbotron {
  background: var(--unit-bg-dark);
}
</style>
<style lang="scss">
.check-outer {
  .custom-checkbox {
    .custom-control-label {
      cursor: pointer;
    }
    .custom-control-label::before,
    .custom-control-label::after {
      top: 23%;
      bottom: 23%;
    }
  }
}
.route-main .list-group-item {
  padding: 0.75rem 0.25rem !important;
}
.margin-bottom-6 {
  margin-bottom: 6px;
}
.route-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
  min-width: 130px;
  max-width: 131px;
  display: block;
}
</style>

<template>
  <b-media
    no-body
    v-if="!(signal_strength === null || signal_strength === undefined)"
  >
    <div class="signal-icon mr-75">
      <div
        :class="
          parseInt(signal_strength) > 0
            ? 'signal level-1 fill'
            : 'signal level-1 '
        "
      ></div>
      <div
        :class="
          parseInt(signal_strength) > 25
            ? 'signal level-2 fill'
            : 'signal level-2 '
        "
      ></div>
      <div
        :class="
          parseInt(signal_strength) > 50
            ? 'signal level-3 fill'
            : 'signal level-3 '
        "
      ></div>
      <div
        :class="
          parseInt(signal_strength) > 75
            ? 'signal level-4 fill'
            : 'signal level-4 '
        "
      ></div>
    </div>

    <h6 class="align-self-center my-auto gsm-text">
      <div v-if="signal_strength === 0">
        <b> {{ $t("NoSignal") }}</b>
      </div>
      <div v-if="signal_strength > 0">
        <b> {{ $t("GSMSignal") }}</b> :
        <span class="info-card-span"> {{ signal_strength }} % </span>
      </div>
    </h6>
  </b-media>
</template>

<script>
import { BMedia, BMediaBody, BImg, BMediaAside, BAvatar } from "bootstrap-vue";

export default {
  name: "gsmSignal",
  components: { BMedia, BMediaBody, BImg, BMediaAside, BAvatar },
  props: ["signal_strength"],
  methods: {},

  data() {
    return {
      speedImage: require("@/assets/images/icons/unit-icons/fuel-level.svg")
    };
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.media {
  align-items: center !important;
  padding-top: 10px;
}
.signal-icon {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: flex-end;
  .signal {
    width: 4px;
    margin-right: 1.5px;
    border-radius: 2px 2px 0px 0px;
    border: 1px solid var(--primary);
  }
  .level-1 {
    height: 9px;
  }
  .level-2 {
    height: 12px;
  }
  .level-3 {
    height: $px_15;
  }
  .level-4 {
    height: 18px;
  }
  .fill {
    background-color: var(--primary);
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{staticClass:"align-item-center event-Tag plus-one-mobile"},[_vm._l((_vm.tags),function(tag,index){return _c('div',{key:index},[(typeof tag === 'string' ? tag : tag.name || '')?_c('span',[(index < _vm.maxTagShow)?_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"padding-right":"3px"}},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:({
              title:
                (tag && tag.length > _vm.showTextInBadge) ||
                (tag && tag.name && tag.name.length > _vm.showTextInBadge)
                  ? tag.name || tag
                  : '',
              customClass: 'tooltip-card'
            }),expression:"{\n              title:\n                (tag && tag.length > showTextInBadge) ||\n                (tag && tag.name && tag.name.length > showTextInBadge)\n                  ? tag.name || tag\n                  : '',\n              customClass: 'tooltip-card'\n            }",modifiers:{"hover":true,"top":true}}],staticClass:"mb-1-4",attrs:{"variant":_vm.variant ? _vm.variant : 'primary',"pill":""}},[_vm._v(" "+_vm._s(_vm.formattedDescriptions(tag.name || tag, _vm.showTextInBadge))+" ")])],1):_vm._e()]):_vm._e()])}),(_vm.tags && _vm.tags.length > _vm.maxTagShow)?_c('b-badge',{staticClass:"cursor-pointer plus-badge",attrs:{"variant":_vm.variant ? _vm.variant : 'primary',"pill":"","id":_vm.event.id + "id","tabindex":"0"}},[_vm._v(_vm._s(_vm.tags.length > _vm.maxTagShow ? Number(_vm.tags.length) - _vm.maxTagShow + "+" : "+")+" ")]):_vm._e(),_c('b-popover',{attrs:{"target":_vm.event.id + "id","triggers":"focus","placement":"lefttop","show":_vm.showPopover},on:{"update:show":function($event){_vm.showPopover=$event}}},[_c('div',{staticClass:"d-flex-tool",style:(_vm.tags.length ? ("" + ('width:' + _vm.tags.length * 30)) : 'width:300px')},_vm._l((_vm.tags),function(tag,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"padding":"3px"}},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(typeof tag === "string" ? tag : tag.name || "")+" ")])],1)])}),0)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
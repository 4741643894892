<template>
  <div>
    <!-- Table Container Card -->
    <b-card class="mb-0 unit-list-vi">
      <div
        class="header-coman-area filter-btn-add unit-header unit-filter-modal"
      >
        <div class="filter-outer">
          <div>
            <h4 class="card-title">{{ $t("unit.Units") }}</h4>
          </div>
          <div class="filter-btn-outer" v-if="!isGroupEntity">
            <b-button variant="primary" @click="showFilter = !showFilter">
              <feather-icon icon="FilterIcon" size="15" />
            </b-button>
          </div>
        </div>
        <div
          class="right-side-btn"
          v-if="!isGroupEntity"
          :class="showFilter ? ' mm' : ' mm2'"
        >
          <div :class="showFilter ? 'mr-15 mm' : 'mr-15 filter-btn mm2'">
            <div class="addXIcon">
              <feather-icon
                class="sizeIcon"
                @click="showFilter = !showFilter"
                icon="XIcon"
                size="15"
              />
            </div>
            <label class="search-label mt-1">{{ $t("unit.Entries") }}</label>
            <v-select
              :clearable="false"
              id="vue-select"
              class="per-page-selector d-inline-block fix-83"
              v-model="perPage"
              :options="option"
            />
          </div>
          <div
            :class="
              showFilter ? 'mr-15' : 'mr-15 filter-btn content-end-search'
            "
            v-if="!isGroupEntity"
          >
            <div
              class="d-flex align-items-center justify-content-end search-field-ls-view"
            >
              <b-form-group class="next-child">
                <b-input-group class="height-100-child">
                  <b-form-input
                    v-model="filter"
                    debounce="1000"
                    class="d-inline-block search-bg"
                    :placeholder="$t('device.searchPlaceholder')"
                  ></b-form-input>
                  <b-input-group-append class="input-prefix-img search-clear"
                    ><feather-icon
                      icon="XIcon"
                      size="24"
                      @click="
                        (e) => {
                          filter = '';
                        }
                      "
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <div
                class="AccountFlid"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.ACCOUNT_READ,
                    subject: constants.PERMISSIONS_MODEL.ACCOUNT
                  }) ||
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.CONSUMER_ACCOUNT_READ,
                    subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                  })
                "
              >
                <AccountSearch
                  class="unit-search-v2"
                  v-model="selectedAccount"
                  :placeholderName="$t('UnitTrack.AccountUnits')"
                  :all="true"
                />
              </div>
            </div>
          </div>
          <div
            class="mr-15 last-2-50 button-set add-AddUnit"
            v-if="!isGroupEntity"
          >
            <b-button
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.UNITS
                })
              "
              variant="primary"
              :to="{ name: 'unit-creation' }"
            >
              <feather-icon icon="PlusIcon" size="12" class="add-icon-s" />
              <span class="d-none d-sm-inline">{{ $t("device.AddUnit") }}</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="list-view-tab" v-if="listView">
        <div class="table-responsive" v-if="showLoading">
          <b-skeleton-table
            v-if="showLoading"
            :rows="this.perPage || 10"
            :columns="5"
            :table-props="{ bordered: true, striped: true }"
          />
        </div>
        <b-table
          v-if="!showLoading"
          class="position-relative sim-icon-mb header-left-align unit-ls-scroll"
          responsive
          show-empty
          sticky-header
          align-v="end"
          :items="items"
          :fields="tableColumns"
          :empty-text="$t('NoMatchingRecordsFound')"
        >
          <template #cell(name)="row">
            <b-media class="align-item-center unit-list-map-icon-parent-1 pt-0">
              <template #aside>
                <span class="unit-list-map-icon-parent">
                  <b-avatar if square size="40" :src="row.item.icon" />
                </span>
              </template>
              <h6 :class="!row.item.archived ? 'mb-0' : ''">
                {{ `${row.item.name ? `${row.item.name}` : " "}` }}
              </h6>
              <template #title>
                <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
                <span class="d-none d-sm-inline">{{ $t("user.User") }}</span>
              </template>
            </b-media>
          </template>
          <template #cell(unit_attached_to)="row">
            {{
              row.item.allotted_account ? row.item.allotted_account.name : ""
            }}</template
          >

          <template #cell(device)="row">
            <ul
              v-if="row.item.devices && row.item.devices.length"
              class="device-list mb-0"
            >
              <li
                :class="!isConsumerAccount ? 'device-list-li' : ''"
                @click="redirectToDevice(device)"
                :key="device.id"
                v-for="device in row.item.devices"
              >
                <b-link
                  v-if="!isConsumerAccount"
                  :to="{
                    name: 'view-device',
                    params: {
                      id: device.id
                    },
                    query: { page: parseInt(currentPage ? currentPage : 1) }
                  }"
                >
                  {{ device.name }}
                </b-link>
                <span v-if="isConsumerAccount">
                  {{ device.name }}
                </span>
              </li>
            </ul>
          </template>

          <template #cell(unit_allocated_to)="row">{{
            row.item.allotted_account ? row.item.allotted_account.name : ""
          }}</template>
          <template #cell(unit_created)="row">{{
            row.item.owner_account ? row.item.owner_account.name : ""
          }}</template>
          <template #cell(unit_driver)="row">{{
            `${
              row.item["user.first_name"]
                ? `${row.item["user.first_name"]}`
                : " "
            }` +
            " " +
            `${
              row.item["user.last_name"] ? `${row.item["user.last_name"]}` : " "
            }`
          }}</template>
          <template #cell(status)="row"
            ><b-badge
              class="badge-cust"
              :variant="resolveFilterBadgeColor(row.item.status)"
            >
              {{ $t(`unit.StatusList.${row.item.status}`) }}
            </b-badge></template
          >
          <template #cell(labels)="row">
            <div class="parent" id="parent">
              <DynamicBadge
                :isList="true"
                :entityType="'UNIT'"
                :tagsData="row.item.labels"
                :showTextInBadge="Number(7)"
                :event="row.item"
                :maxTagShow="Number(defaultBadgeShow)"
                :listApi="debounceGetAllUnitList"
              ></DynamicBadge>
            </div>
          </template>
          <template #cell(ACTIONS)="row">
            <div class="text-right track_tool">
              <!-- <span :id="row.item.track_tool" class="track-anchor">
 <b-link
 :to="{ name: 'track-unit', params: { id: row.item.id } }"
 >
 <RouteIcon class="curserPointer imgss track-icon" />
 </b-link>
 </span> -->
              <b-tooltip
                delay="100"
                variant="primary"
                :target="row.item.track_tool"
                >{{ $t("tooTip.trackUnit") }}</b-tooltip
              >
              <span
                v-b-tooltip.hover.top.v-primary
                :title="$t('tooTip.allocation')"
                v-b-modal.unit-allocate
                @click="allocationUnit(row.item)"
                v-if="
                  !restrictAllocation &&
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.UPDATE,
                    subject: constants.PERMISSIONS_MODEL.UNITS
                  })
                "
              >
                <AllocationIcon class="allocate-icon" />
              </span>
              <span :id="row.item.id">
                <b-link
                  :to="{
                    name: 'view-unit',
                    query: {
                      is_unit_only_allocated: row.item.is_unit_only_allocated,
                      isUnitOwner: row.item.is_unit_owner
                    },
                    params: {
                      id: row.item.id,
                      isEnabled: true
                    }
                  }"
                  v-if="
                    checkAbility({
                      action: constants.PERMISSIONS_ACTION.READ,
                      subject: constants.PERMISSIONS_MODEL.UNITS
                    })
                  "
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="curserPointer text-primary action-icon"
                    size="18"
                  />
                </b-link>
              </span>
              <span :id="row.item.up_tool">
                <b-link
                  v-if="
                    row.item.is_unit_editable &&
                    checkAbility({
                      action: constants.PERMISSIONS_ACTION.UPDATE,
                      subject: constants.PERMISSIONS_MODEL.UNITS
                    })
                  "
                  :to="{
                    name: 'edit-unit',
                    query: {
                      is_unit_only_allocated: row.item.is_unit_only_allocated,
                      isUnitOwner: row.item.is_unit_owner
                    },
                    params: {
                      id: row.item.id,
                      isEnabled: false
                    }
                  }"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="curserPointer text-primary action-icon"
                    size="18"
                  />
                </b-link>
              </span>
              <span>
                <feather-icon
                  v-b-tooltip.hover.top.v-primary
                  :title="$t('tooTip.delete')"
                  v-if="
                    row.item.is_unit_editable &&
                    checkAbility({
                      action: constants.PERMISSIONS_ACTION.DELETE,
                      subject: constants.PERMISSIONS_MODEL.UNITS
                    })
                  "
                  icon="Trash2Icon"
                  class="text-danger action-icon curserPointer"
                  size="18"
                  v-b-modal.modal-sm-remove
                  @click="deleteUnit(row.item)"
                />
              </span>
            </div>

            <b-tooltip
              delay="100"
              variant="primary"
              :target="row.item.up_tool"
              >{{ $t("tooTip.update") }}</b-tooltip
            >
            <b-tooltip delay="100" variant="primary" :target="row.item.id">{{
              $t("tooTip.view")
            }}</b-tooltip>
          </template>
        </b-table>
        <div class="mx-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              &nbsp;
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-if="totalUnits > 0"
                v-model="currentPage"
                :total-rows="totalUnits"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                :no-paging-nav="false"
                :active-class="activeClass"
                @input="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <AreYouSureModal
      :data="deleteData"
      :component="c1"
      :status="status"
      :onClose="onClose"
      :removedUser="removedUser"
    />

    <AllocationPopUp
      v-if="isAllocationEnable"
      :allocationData="allocationData"
      :isDevice="false"
      :isSelectedTab="isSelectedTab"
      :onClose="onClose"
      :changeInput="changeInput"
      :currentUnit="currentUnit"
      :refreshed="refreshed"
      :getAccountDetail="getAccountDetail"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BMediaBody,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BFormGroup,
  BInputGroupAppend,
  BModal,
  BForm,
  BImg,
  BFormCheckbox,
  BButtonGroup,
  BFormRadioGroup,
  BFormRadio,
  BCardHeader,
  BCollapse,
  BCardBody,
  BCardText,
  BMediaAside,
  BTooltip,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import UnitService from "@/libs/api/unit-service";
import DataService from "@/libs/api/data-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import AllocationPopUp from "./AllocationPopUp.vue";
import RouteIcon from "@/assets/images/new-icon/route1.svg";
import AllocationIcon from "@/assets/images/icons/allocation.svg";
import {
  LMap,
  LTileLayer,
  LMarker,
  LCircle,
  LPolygon,
  LIcon,
  LPopup,
  LTooltip
} from "vue2-leaflet";
import ImageFallBack from "vue-fall-back-image-directive";
import { latLng, Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
import AccountSearch from "@/views/Unit/Unit-V2/AccountSearch.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import SensorInfo from "../sensor-views/sensorInfo.vue";
import SocketIOService from "@/libs/socket/sockets";
import constants from "@/utils/constants";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaBody,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BModal,
    BForm,
    AreYouSureModal,
    BImg,
    AllocationPopUp,
    BFormCheckbox,
    BButtonGroup,
    BFormRadioGroup,
    BFormRadio,
    BCardHeader,
    BCollapse,
    BCardBody,
    BCardText,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LTooltip,
    BMediaAside,
    AppCollapse,
    AppCollapseItem,
    ImageFallBack,
    BTooltip,
    SensorInfo,
    VBTooltip,
    BSkeletonTable,
    RouteIcon,
    AllocationIcon,
    DynamicBadge,
    AccountSearch,
    Treeselect
  },
  data() {
    return {
      tableColumns: [
        {
          key: "name",
          tdClass: "mb-table-cell",
          thClass: "mb-table-head",
          sortable: true,
          label: this.$t("unit.unitName")
        },
        {
          key: "device",
          tdClass: "mb-table-cell",
          thClass: "mb-table-head",
          label: this.$t("unit.device")
        },
        {
          key: "unit_allocated_to",
          tdClass: "mb-table-cell",
          thClass: "mb-table-head",
          label: this.$t("unit.unit_allocated_to")
        },
        {
          key: "unit_created",
          tdClass: "mb-table-cell",
          thClass: "mb-table-head",
          label: this.$t("unit.unit_created")
        },
        {
          key: "status",
          tdClass: "mb-table-cell status-width",
          thClass: "mb-table-head status-width",
          label: this.$t("unit.status")
        },
        {
          key: "labels",
          tdClass: "mb-table-cell badge-230",
          thClass: "mb-table-head badge-230",
          label: this.$t("unit.labels")
        },
        {
          key: "ACTIONS",
          tdClass: "mb-table-cell action-width-unit-list",
          thClass: "mb-table-head action-width-unit-list",
          label: this.$t("unit.actions")
        }
      ],
      selected: "10",
      option: ["10", "20", "30"],
      c1: null,
      status: false,
      deleteData: null,
      totalDevices: null,
      totalUnits: null,
      perPage: this.$route.query.perPage ? this.$route.query.perPage : this.nearestPage(["10", "20", "30"],parseInt(window.innerHeight / 62)),
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      isAllocationEnable: false,
      showFilter: false,
      currentPageUnit: 1,
      activeClass: "active",
      items: [],
      clearPage: false,
      unitsData: null,
      unitsData1: [],
      downloadUrl: process.env.VUE_APP_FILE_URL,

      filter: this.$route.query.search || "",
      allocationData: null,
      currentUnit: null,
      listView: false,

      selectedView: "mapScreen",
      markerArray: [],
      iconSize: 40,

      url: process.env.VUE_APP_ICON_URL,
      timeoutId: null,
      showLoading: "false",
      isConsumerAccount: true,
      restrictAllocation: false,
      selectedAccount: null,
      accountId: "",
      defaultBadgeShow: 2,
      accountsList: [],
      isConsumer:
        localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER" ? true : false
    };
  },
  props: ["isGroupEntity", "groupName"],
  setup() {
    const resolveFilterBadgeColor = (filter) => {
      filter = filter && filter.toUpperCase();
      const status = {
        ACTIVE: "light-success",
        INACTIVE: "light-warning",
        DAMAGED: "light-danger",
        BLOCKED: "light-secondary"
      };

      const selectedStatus =
        filter && status[filter] ? status[filter] : status["ACTIVE"];

      return selectedStatus;
    };
    return {
      resolveFilterBadgeColor
    };
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  },
  watch: {
    perPage(val) {
      // if (!this.perPage) {
      //   this.perPage = 10;
      // }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.debounceGetAllUnitList();
    },

    currentPage(newPage) {
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            account_id: this.selectedAccount,
            page: newPage
          }
        })
        .catch((e) => {});
      this.debounceGetAllUnitList();
    },

    filter(newFilter) {
      this.$router
        .replace({
          query: { ...this.$route.query, account_id: this.selectedAccount }
        })
        .catch((e) => {});
      // this.updateUrl({ key: "filter", value: newFilter });
      this.debounceGetAllUnitList();
    },
    selectedAccount() {
      this.$router
        .replace({
          query: { ...this.$route.query, account_id: this.selectedAccount }
        })
        .catch((e) => {});
      this.debounceGetAllUnitList();
    }
  },

  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }
      this.debounceGetAllUnitList();
    });

    if (
      this.checkAbility({
        action: constants.PERMISSIONS_ACTION.READ,
        subject: constants.PERMISSIONS_MODEL.LABELS
      })
    ) {
      this.tableColumns.splice(-1, 0, {
        key: "labels",
        tdClass: "operator-group-width-rg labels-width",
        thClass: "operator-group-width-rg labels-width"
      });
    }
    this.restrictAllocation =
      localStorage.getItem("USER_SUB_ACCOUNTS_RESTRICTED") == "true"
        ? true
        : false;
    this.listView = this.$route.name === "unit-list" ? true : false;
    this.selectedView =
      this.$route.name === "unit-list" ? "listScreen" : "mapScreen";
    this.isGroupEntity ? (this.listView = this.isGroupEntity) : "";
    this.selectedAccount = this.$route.query.account_id;
    //  this.getAllUnitList();
    window.addEventListener("resize", this.showLabelSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("resize", this.showLabelSize);
  },
  methods: {
    debounceGetAllUnitList() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllUnitList();
      }, 300); // Adjust the delay as necessary
    },
    updateSearch(value) {
      this.filter = value;
    },

    updateUrl(search) {
      const query = { ...this.$route.query };

      if (!search.value) {
        delete query[search.key];
      } else {
        query[search.key] = search.value;
      }

      this.$router.push({ path: this.$route.path, query });
    },

    showLabelSize() {
      setTimeout(() => {
        const parentElement = document.querySelector(".parent");
        if (parentElement) {
          const parentWidth = parentElement.offsetWidth;
          this.defaultBadgeShow = Math.floor(parentWidth / 71);
        }
      }, 200);
    },
    redirectToTrackUnit(unit) {
      this.$router.push({
        name: "track-unit",
        params: {
          id: unit.id
        }
      });
    },
    redirectToDevice(device) {
      if (this.isConsumerAccount) {
        return;
      }
      this.$router.push({
        name: "view-device",
        params: {
          id: device.id
        },
        query: { page: parseInt(this.currentPage) }
      });
    },

    listViewIconPressed() {
      if (this.selectedView === "listScreen") {
        this.listView = true;
        this.$router.push({ name: "unit-list" });
      } else {
        this.$router.push({ name: "unit-map" });
        this.listView = false;
      }
    },

    async changeInput(allocationData) {
      this.allocationData = allocationData;
    },

    allocationUnit(props) {
      this.isAllocationEnable = true;
      setTimeout(() => {
        this.$bvModal.show("unit-allocate");
      }, 2);

      this.allocationData = {
        ...props,
        start_date: props.allotted_account.start_date,
        end_date: props.allotted_account.end_date,
        allocate_to_id: props.allotted_account.id,
        unit_driver_id: props.operator.id
      };
      this.currentUnit = Object.assign(props, {});
      this.currentUnit.allocate_to_id = this.currentUnit.allotted_account.id;
      this.currentUnit.unit_driver_id = this.currentUnit.operator.id;
    },
    refreshed() {
      this.debounceGetAllUnitList();
    },
    editUnit(props, isEditable) {
      this.$router.push({
        name: "edit-unit",
        params: { id: props, isEnabled: isEditable }
      });
    },
    viewUnit(props, isEditable) {
      this.$router.push({
        name: "view-unit",
        params: { id: props, isEnabled: isEditable }
      });
    },
    deleteUnit(props) {
      this.c1 = "src/layouts/components/AreYouSureModal.vue";
      this.status = true;
      this.deleteData = { name: props.name, id: props.id };
    },
    getAccountDetail(data) {
      this.isConsumerAccount = data.type == "CONSUMER";
      // this.tableColumns[2] =
      //   data.type == "CONSUMER" ? "unit_driver" : "unit_allocated_to";
    },
    async removedUser(props) {
      this.deleteData = { name: "", id: "" };
      if (props.id) {
        try {
          this.onClose();
          let response = await new UnitService().deleteUnit({
            id: props.id
          });

          if (response && response.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("unit.DeletedSuccessfully"),
                icon: "EditIcon",
                variant: "success"
              }
            });

            this.debounceGetAllUnitList();
          } else if (response && response.error && response.error.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "EditIcon",
                variant: "error"
              }
            });
          }
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove");
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };

      this.$router.replace({ query }).catch(() => {});
    },
    async getAllUnitList() {
      try {
        this.showLoading = true;
        this.currentPage =
          parseInt(this.$route.query.page) || this.currentPage || 1;
        let requestData = {
          page: this.listView ? this.currentPage : this.currentPageUnit,
          page_size: parseInt(this.perPage)
        };
        if (this.filter) {
          requestData.filters = [
            {
              field: "all",
              value: this.filter,
              operator: "ilike"
            }
          ];
        } else {
          if (this.$route.query && this.$route.query.filter) {
            requestData.filters = [
              {
                field: "all",
                value: this.$route.query.filter,
                operator: "ilike"
              }
            ];
          } else {
            requestData.filters = [];
          }
        }
        if (this.groupName) {
          requestData.label = this.groupName;
        }
        requestData.account_id = this.$route.query.account_id;
        let response = await new UnitService().getUnitListV2(requestData);
        this.showLoading = false;
        if (response && response.data) {
          this.items = response.data.list;
          this.items = this.items.map((u) => {
            u.allocate_tool = `allocate${u.id}`;
            u.track_tool = `track${u.id}`;
            u.up_tool = `up${u.id}`;
            u.del_tool = `del${u.id}`;
            u.labels = u.labels || [];
            return u;
          });
          this.totalDevices = response.data.pagination.total_records;

          this.unitsData1 = [...this.unitsData1, ...response.data.list];

          this.unitsData = response.data.list;

          this.totalUnits = response.data.pagination.total_records;

          if (this.clearPage) {
            this.$router.replace({ query: {} });
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.showLabelSize();
    },
    isSelectedTab() {
      this.$bvModal.hide("is-unit-allocated");
    }
  }
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "./index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      top: -1px !important;
    }
  }
}
.dark-layout .b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      color: #d0d2d6 !important;
    }
  }
}
.vue2leaflet-map {
  &.leaflet-container {
    // height: 65vh !important;
    font-family: inherit;
  }
}

.content-end-search {
  .d-flex {
    .form-group {
      margin-bottom: 0rem !important;
    }
  }
}

.no-sensor-data {
  padding-left: 30px;
  padding-bottom: 20px !important;
}

.no-sensor-data-1 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.no-sensor-data-2 {
  padding-left: 30px;
}

.no-sensor-data-4 {
  padding-left: 30px;
  padding-bottom: 10px;
}

.no-sensor-data-5 {
  margin-bottom: 6px;
}

.map-outer {
  width: 100%;
}

.track-anchor {
  margin-right: 10px;
}

.badge-cust {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: var(--red2);
  padding: 4px 9px !important;
}

.tooltip-info-on-map {
  .card-body {
    padding: 0px !important;
  }

  .modal-info {
    padding-left: 17px;
    padding-right: 17px;

    .media {
      min-width: 20px;

      h6 {
        padding-left: 9px;
      }

      img {
        max-height: 20px;
        max-width: 20px;
        margin-right: 0px !important;
      }
    }

    .marginLeftImg {
      margin: 0 !important;
      padding: 0 !important;
    }

    .b-avatar.badge-light-primary {
      background-color: transparent !important;
    }
  }

  .align-self-center.my-auto {
    font-size: 11px;
  }

  .btn.full-btn.d-flex.align-items-center.btn-primary {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .signal-icon {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: baseline;

    .signal {
      width: 4px;
      border: 1px solid var(--primary);
      margin-right: 1.5px;
      border-radius: 2px 2px 0px 0px;
    }

    .level-1 {
      height: $px_10;
    }

    .level-2 {
      height: 13px;
    }

    .level-3 {
      height: $px_15;
    }

    .level-4 {
      height: 18px;
    }

    .fill {
      background-color: var(--primary);
    }
  }

  .card-header {
    .collapse-title {
      font-size: 14px;
      color: $headings-color;
      font-weight: 500;
    }
  }
}

.unit12 {
  .b-avatar {
    .b-avatar-img {
      img {
        margin-bottom: 20px;
        position: absolute;
        padding: 5px;
      }
    }
  }
}

.map-col {
  float: left;
  width: 70%;
  background-color: $gray8;
  min-height: 65vh;
  margin-bottom: $px_10;
}

.list-col {
  float: left;
  width: calc(30% - 35px);
  margin-left: 20px;
  min-height: 65vh;
  margin-bottom: $px_10;
}

.unit-card {
  min-height: 65vh;
}

.map-row {
  width: $percent_100;
  height: $percent_100;
}

svg.route-svg,
.route-svg path {
  fill: var(--primary);
}

.action-width-unit-list {
  max-width: 200px;
  width: 200px;
  min-width: 200px;
}

.status-width {
  max-width: 130px;
  width: 130px;
  min-width: 130px;
  text-align: center;
}
.status-width-right {
  max-width: 170px;
  width: 170px;
  min-width: 170px;
  text-align: right !important;
}
.group-width {
  max-width: 180px;
  width: 180px;
  min-width: 180px;
  text-align: left;

  .event-Tag .media-body {
    justify-content: flex-start;
  }
}

.search-clear {
  padding: 6px 5px 0px 5px;
  border-radius: 0rem 0.357rem 0.357rem 0rem;
}
.operator-group-width-rg {
  max-width: 170px;
  width: 170px;
  min-width: 170px;
  text-align: left !important;
}
.AccountFlid {
  width: 15vw;
  margin-left: 10px;
}

@media only screen and (max-width: 991px) {
  .unit-header {
    position: relative;
  }
  .AccountFlid {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
  }

  .button-set {
    position: absolute;
    top: 6px;
    right: 84px;
    text-align: right;
  }

  .unit-filter-modal {
    position: relative;

    .right-side-btn {
      width: 100%;
      max-width: 300px;
      position: absolute;
      top: 50px;
      z-index: 9;
      right: 0;
      background: var(--white);
      border-radius: 6px;
      padding: 10px;

      .search-label {
        width: 100%;
      }

      .per-page-selector.fix-83 {
        width: 100%;
      }

      .button-set {
        position: absolute;
        top: -53.5px;
        right: 80px;
        .add-icon-s {
            margin-right: 0;
            margin-bottom: 0;
        }
      }
    }

    .mm2 {
      background: none;
    }
  }

  .dark-layout {
    .unit-filter-modal {
      position: relative;

      .right-side-btn {
        background-color: $theme-dark-card-bg;
      }

      .mm2 {
        background: none;
      }
    }
  }
  .mb-table-head {
    white-space: nowrap !important;
  }
}
@media only screen and (max-width: 766px) {
  .unit-filter-modal .right-side-btn .button-set {
    right: 65px;
  }
}
.vue-treeselect__control {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 37px !important ;
}
</style>
<style scoped lang="scss">
.form-group {
  margin-bottom: 0rem;
}
@media only screen and (max-width: 991px) {
  .search-field-ls-view {
    flex-wrap: wrap;
  }
  // .add-AddUnit a {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   .add-icon-s {
  //     margin-right: 0px;
  //   }
  // }
  .header-coman-area .right-side-btn .last-2-50 {
    width: 61px;
  }
}
.add-icon-s {
  vertical-align: middle !important;
  margin-right: 5px;
}
</style>
<style lang="scss" scoped>
.b-table-sticky-header {
  max-height: calc(100vh - 250px) !important;
  overflow-y: auto;
}
.list-view-tab {
  .table-responsive {
    max-height: calc(100vh - 250px) !important;
    min-height: calc(100vh - 250px) !important;
    overflow-y: auto;
  }
}
</style>

<template>
  <div class="group-card-list">

   <UnitList v-if="selectedEntityType == constants.GROUP_ICONS.UNITS"  
    :isGroupEntity= "isGroupEntity"
    :groupName ="groupName"/>
   <DeviceList v-if="selectedEntityType == constants.GROUP_ICONS.DEVICES" 
   :isGroupEntity= "isGroupEntity"
   :groupName ="groupName"
   />
   <Alert v-if="selectedEntityType == constants.GROUP_ICONS.ALERTS"
   :isGroupEntity= "isGroupEntity"
   :groupName ="groupName"/>
   <!-- <NotificationIcon v-if="entityType == 'NOTIFICATIONS'"/>
   <ScheduleIcon v-if="entityType == 'SCHEDULES'"/>
   <WebhookIcon v-if="entityType == 'WEBHOOKS'"/>
   <RepeaterIcon v-if="entityType == 'REPEATERS'"/>-->
   <OperatorList v-if="selectedEntityType == constants.GROUP_ICONS.OPERATORS"
   :isGroupEntity= "isGroupEntity"
   :groupName ="groupName"/> 
   
   <ReportsList v-if="selectedEntityType == constants.GROUP_ICONS.REPORTS"
   :isGroupEntity= "isGroupEntity"
   :groupName ="groupName"/> 
   <MaintenanceReminderList v-if="selectedEntityType == constants.GROUP_ICONS.MAINTENANCE"
   :isGroupEntity= "isGroupEntity"
   :groupName ="groupName"/> 
   <GeofencesList v-if="selectedEntityType == constants.GROUP_ICONS.GEOFENCES"
   :isGroupEntity= "isGroupEntity"
   :groupName ="groupName"/>      
   <RoutesList v-if="selectedEntityType == constants.GROUP_ICONS.ROUTES"
   :isGroupEntity= "isGroupEntity"
   :groupName ="groupName"/> 
  </div>
</template>
    <script>
      import UnitList from "@/views/Unit/UnitListView.vue";
      import DeviceList from "@/views/DeviceOnboarding/DevicesList.vue";
      import Alert from "@/views/Alert/Alert.vue";
      import ReportsList from "@/views/Reports/ReportsList.vue"
      import MaintenanceReminderList from "@/views/Maintenance/MaintenanceReminderList.vue"
      import GeofencesList from "@/views/Geofence/GeofencesList.vue";
      import RoutesList from '@/views/Route/RoutesList.vue'
      // import NotificationIcon from "@/assets/images/icons/group-icons/Notifications.svg";
      // import ScheduleIcon from "@/assets/images/icons/group-icons/Schedules.svg";
      // import WebhookIcon from "@/assets/images/icons/group-icons/Webhooks.svg";
      // import RepeaterIcon from "@/assets/images/icons/group-icons/Repeaters.svg";
      import OperatorList from "@/views/Operators/Operators.vue";
      import constants from "@/utils/constants";

   
      export default {
        components: {
          UnitList,
          Alert,
          DeviceList,
          RoutesList,
          // NotificationIcon,
          // ScheduleIcon,
          // WebhookIcon,
          // RepeaterIcon,
          OperatorList,
          ReportsList,
         MaintenanceReminderList,
         GeofencesList
        },
         data() {
         return {
           isGroupEntity:true
           }
         },
        props: ["selectedEntityType","groupName"]
      };
    </script>

    <style lang="scss">
      @import "@core/scss/vue/libs/vue-select.scss";
    </style>
  </template>
</template>
<style lang="scss" >  
.group-card-list {
  .report-page .b-table-sticky-header {
    height: calc(100vh - 554px) !important;
     min-height: calc(100vh - 554px) !important;
    overflow-y: auto;  
  }
  .report-page .report-table{
      min-height: 0 !important;
  }
  .report-page{
    min-height: 0 !important;
  }

  .operator-table-width .b-table-sticky-header {
      max-height: calc(100vh - 534px) !important;
      min-height: calc(100vh - 534px) !important;
      overflow-y: auto;
      .filter-btn-outer-new{
    display: none !important;
  }
  }
  .device-list{
    max-height: calc(100vh - 540px) !important;
    min-height: calc(100vh - 540px) !important;
    overflow-y: auto;
  }
   ul.device-list{
    min-height: auto !important;
    max-height: none !important;
  }
  .de-pdb{
    padding-bottom: 0 !important;
  }
  .alert-notification .table-responsive {
      max-height: calc(100vh - 530px) !important;
      min-height: calc(100vh - 530px) !important;
      overflow-y: auto;
  }
  .list-view-tab .table-responsive{
      max-height: calc(100vh - 520px) !important;
      min-height: calc(100vh - 520px) !important;
      overflow-y: auto;
  }
}
.group-card-list{
  .card-body{
    padding: 0 !important;
  }
}
</style>
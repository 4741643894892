<template>
  <b-media
    no-body
    v-if="
      !(ignition === null || ignition === undefined) ||
      !(is_moving === null || is_moving === undefined)
    "
  >
    <b-media-aside class="mr-75 marginLeftImg">
      <vehicle-status-icon />
    </b-media-aside>
    <h6 class="align-self-center my-auto">
      <b> {{ $t("VehicleStatus") }}</b> :
      <span class="vehicle-status-cls"> {{ getVehicleStatus() }}</span>
    </h6>
  </b-media>
</template>

<script>
import { BMedia, BMediaBody, BImg, BMediaAside, BAvatar } from "bootstrap-vue";
import VehicleStatusIcon from "@/assets/images/icons/unit-icons/status.svg";
export default {
  name: "vehicleStatus",
  components: {
    BMedia,
    BMediaBody,
    BImg,
    BMediaAside,
    BAvatar,
    VehicleStatusIcon
  },
  props: ["is_moving", "ignition"],
  methods: {
    getVehicleStatus() {
      if (this.is_moving === 1 && this.ignition === 1) {
        return "Moving";
      } else if (this.is_moving === 1 && this.ignition === 0) {
        return "Towing";
      } else if (this.is_moving === 0 && this.ignition === 1) {
        return "Idling";
      } else if (this.is_moving === 0 && this.ignition === 0) {
        return "Parked";
      } else {
        return "Unknown";
      }
    }
  },

  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.media {
  align-items: center !important;
  padding-top: 10px;
}
.vehicle-status-cls {
  font-weight: bold;
  color: #7367f0;
}
</style>

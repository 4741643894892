<template>
  <narasi-player
    :options="videoOptions"
    @ready="
      (player) => {
        onReady(player);
      }
    "
  />
</template>
<script>
const DEFAULT_OPTIONS = {
  preload: "auto",
  playbackRates: [0.25, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7],
  errorDisplay: false,
  textTrackSettings: true,
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: false
};
import "narasi-player/dist/narasi-player.css";
import NarasiPlayer from "narasi-player";

export default {
  name: "PolyVideoPlayer",
  components: {
    NarasiPlayer
  },
  props: {
    poster: {
      type: String
    },
    media: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default(rawProps = {}) {
        return {
          ...DEFAULT_OPTIONS,
          ...rawProps,
          ...{
            playbackRates: this.options.liveui
              ? []
              : DEFAULT_OPTIONS.playbackRates
          }
        };
      }
    }
  },
  watch: {
    options: {
      deep: true,
      handler(newOptions) {
        this.videoOptions = {
          ...this.videoOptions,
          ...DEFAULT_OPTIONS,
          ...newOptions,
          ...{
            playbackRates: this.options.liveui
              ? []
              : DEFAULT_OPTIONS.playbackRates
          }
        };
      }
    },
    poster(newPoster) {
      this.videoOptions = {
        ...this.videoOptions,
        poster: newPoster,
        ...{
          playbackRates: this.options.liveui
            ? []
            : DEFAULT_OPTIONS.playbackRates
        }
      };
    },
    media: {
      handler(newMedia) {
        this.videoOptions = {
          ...this.videoOptions,
          sources: [newMedia],
          ...{
            playbackRates: this.options.liveui
              ? []
              : DEFAULT_OPTIONS.playbackRates
          }
        };
      }
    }
  },
  data() {
    return {
      componentKey: 1,
      videoOptions: {
        ...DEFAULT_OPTIONS,
        ...this.options,
        poster: this.poster,
        sources: [this.media],
        ...{
          playbackRates: this.options.liveui
            ? []
            : DEFAULT_OPTIONS.playbackRates
        }
      }
    };
  },
  methods: {
    onReady(player) {
      const me = this;
      player.on("error", function (error) {
        if (this.error_.code === 2 || this.error_.code === 4) {
          setTimeout(() => {
            player.src(me.media);
            player.play();
          }, 1000);
        }
      });
    }
  }
};
</script>
<style lang="scss">
.video-js {
  width: 100%;
  min-height: 300px;
  .vjs-big-play-button {
    background-color: var(--primary);
  }
  &:hover .vjs-big-play-button {
    background-color: var(--primary);
  }
  .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected,
  .vjs-menu-button-popup .vjs-menu .vjs-menu-item:hover,
  .vjs-play-progress,
  .vjs-volume-level {
    background-color: var(--primary);
  }
  .vjs-quality-selector .vjs-button {
    text-transform: capitalize;
  }
  .vjs-playback-rate-value {
    text-transform: capitalize;
    font-size: 15px;
    line-height: 42px;
  }
}
</style>

<template>
  <b-modal
    :id="data && data.modelName ? data.modelName : modelName"
    centered
    size="sm"
    modal-class="no-header-modal"
  >
    <b-card-text>
      <div class="d-flex justify-content-center">
        <b-avatar variant="danger" size="96" class="size-96">
          <feather-icon size="56" :icon="'Trash2Icon'" />
        </b-avatar>
      </div>
      <div>
        <div class="you-invite">
          <!-- <span class="text-primary">{{ data.name }}</span> -->
          {{ $t("geofence.removeTitle") }}
          {{ $t("geofence.removeTitle1") }}
        </div>
        <!-- <div class="you-invite">
        <b>{{ $t("geofence.Notification") }}</b>
      </div> -->
        <div class="you-invite geo-del">
          <div
            class="text-primary alt-name curserPointer"
            v-for="list in data.showData"
            :key="list.id"
          >
            <span @click="redirectAlert(list)" v-html="list.name"> </span>
          </div>
        </div>
        <div class="d-flex">
          <div class="you-invite">
            {{ $t("geofence.geofenceDesc") }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mb-20 mtt-20">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          @click="onClose()"
          class="mt-2"
        >
          {{
            customData && customData.btnTxt
              ? customData.btnTxt
              : $t("user.Cancel")
          }}
        </b-button>
      </div>
    </b-card-text>
  </b-modal>
</template>
<script>
import { BCard, BLink, BButton, BAvatar, BCardText } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
export default {
  name: "AreYouSureModal",
  components: {
    BCard,
    BButton,
    BAvatar,
    BLink,
    BCardText
  },

  directives: {
    Ripple
  },
  watch: {
    data(old, newVal) {}
  },
  props: ["data", "user", "component", "onClose", "removedUser", "customData"],
  data() {
    return {
      modelName: "modal-sm-remove"
      // status: false
    };
  },
  methods: {
    redirectAlert(list) {
      this.$router.push({});
      const routeUrl = this.$router.resolve({
        name: "alert-view",
        target: "_blank",
        params: { id: list.id }
      }).href;
      // Open the URL in a new tab
      window.open(routeUrl, "_blank");
    }
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.delete-conf {
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
  margin-top: 15px;
}
.width-210 {
  width: 240px;
  margin: 0;
  .alt-name {
    text-align: left;
  }
}
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: start;
  color: $body-color;
  margin-top: 10px;
  .alt-name {
    padding-right: 3px;
  }
}
.invite-name {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  font-weight: 600;
  word-break: break-all;
}
.mb-20 {
  margin-bottom: 20px;
}
.mtt-20 {
  margin-top: 0px;
}
.geo-del {
  max-height: 150px;
  overflow: auto;
  padding-right: 10px;
}
</style>

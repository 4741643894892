var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operator-table-width"},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[(_vm.show)?_c('div',{staticClass:"table-responsive"},[(_vm.show)?_c('b-skeleton-table',{attrs:{"rows":10,"columns":5,"table-props":{ bordered: true, striped: true }}}):_vm._e()],1):_vm._e(),(!_vm.show)?_c('b-table',{staticClass:"position-relative sim-icon-mb Operator-table child-1-30-percent",attrs:{"responsive":"","show-empty":"","align-v":"end","items":_vm.items,"sticky-header":"","fields":_vm.tableColumns,"empty-text":_vm.$t('NoMatchingRecordsFound')},scopedSlots:_vm._u([{key:"cell(operator_name)",fn:function(row){return [_c('b-media',{staticClass:"align-items-center",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"50","src":"RA","text":row.item.avtar_txt,"variant":row.item.variant}})]},proxy:true}],null,true)},[_c('h6',{staticClass:"mb-0 d-flex mobile-no-wrap"},[_vm._v(" "+_vm._s(row.item.name)+" ")])])]}},{key:"cell(operator_code)",fn:function(row){return [_c('h6',{staticClass:"mobile-no-wrap mb-0"},[_vm._v(" "+_vm._s(("" + (row.item.code ? ("" + (row.item.code)) : " ")))+" ")])]}},{key:"cell(linked_user)",fn:function(row){return [_c('h6',[_vm._v(" "+_vm._s("" + (row.item.first_name ? ("" + (row.item.first_name)) : " ") + " " + "" + (row.item.last_name ? ("" + (row.item.last_name)) : " "))+" ")])]}},{key:"cell(linked_unit)",fn:function(row){return [(row.item.units.length)?_c('ul',{staticClass:"device-list text-left p-0 mb-0"},_vm._l((row.item.units),function(unit){return _c('li',{key:unit.id,staticStyle:{"list-style-type":"none"}},[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.READ,
                  subject: _vm.constants.PERMISSIONS_MODEL.UNITS
                })
              )?_c('b-link',{attrs:{"target":"_blank","to":{
                name: 'view-unit',
                params: {
                  id: unit.id
                }
              }}},[_c('b',{staticClass:"mobile-no-wrap"},[_vm._v(_vm._s(unit.name))])]):_vm._e(),_c('br'),(unit.start_date && unit.end_date)?_c('span',[_c('h6',[_vm._v(" ("+_vm._s(_vm.formattedDates(unit.start_date))+" To "+_vm._s(_vm.formattedDates(unit.end_date))+") ")])]):_c('span',[_c('h6',[_vm._v(" "+_vm._s(_vm.$t("operators.UnitAllocatedSince") + " " + _vm.formattedDates(unit.start_date))+" ")])])],1)}),0):_vm._e()]}},{key:"cell(labels)",fn:function(row){return [_c('div',{staticClass:"parent",attrs:{"id":"parent"}},[_c('DynamicBadge',{attrs:{"isList":true,"entityType":'OPERATOR',"tagsData":row.item.labels,"showTextInBadge":Number(7),"event":row.item,"maxTagShow":Number(_vm.defaultBadgeShow),"listApi":_vm.debounceGetOperators}})],1)]}},{key:"cell(ACTIONS)",fn:function(row){return [_c('div',{staticClass:"text-left track_tool"},[_c('span',{attrs:{"id":row.item.id}},[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.READ,
                  subject: _vm.constants.PERMISSIONS_MODEL.OPERATORS
                })
              )?_c('b-link',{attrs:{"to":{
                name: 'view-operator',
                params: { id: row.item.id, isEnabled: true }
              }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"curserPointer text-primary action-icon",attrs:{"title":_vm.$t('tooTip.view'),"icon":"EyeIcon","size":"18"}})],1):_vm._e()],1),_c('span',{attrs:{"id":row.item.up_tool}},[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
                  subject: _vm.constants.PERMISSIONS_MODEL.OPERATORS
                })
              )?_c('b-link',{attrs:{"to":{
                name: 'edit-operator',
                params: { id: row.item.id, isEnabled: false }
              }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"curserPointer text-primary action-icon",attrs:{"title":_vm.$t('tooTip.update'),"icon":"EditIcon","size":"18"}})],1):_vm._e()],1),_c('span',[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.DELETE,
                  subject: _vm.constants.PERMISSIONS_MODEL.OPERATORS
                })
              )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}},{name:"b-modal",rawName:"v-b-modal.modal-sm-remove",modifiers:{"modal-sm-remove":true}}],staticClass:"text-danger action-icon curserPointer",attrs:{"title":_vm.$t('tooTip.delete'),"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteOperator(row.item)}}}):_vm._e()],1)])]}}],null,false,3285816451)}):_vm._e(),_c('div',{staticClass:"mx-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[(_vm.totalOperators > 0)?_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalOperators,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item","no-paging-nav":false,"active-class":_vm.activeClass},on:{"input":_vm.handlePageChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],1)],1),_c('AreYouSureModal',{attrs:{"data":_vm.removedOperatorData,"onClose":_vm.onClose,"removedUser":_vm.removeOperator}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
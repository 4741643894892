<template>
  <div>
    <b-modal
      id="unit-allocate"
      centered
      size="lg"
      modal-class="no-header-modal"
      @hide="closeUnitAllocate"
    >
      <b-card-text class="modal-h-s">
        <h4 class="text-center">
          {{
            isDevice
              ? allocationData && allocationData.allocatedToId
                ? $t("device.ReallocateTo")
                : $t("device.AllocateTo")
              : $t("unit.UnitAllocation")
          }}
        </h4>
        <div
          class="unit-scroll-a"
          :class="
            $route.name === 'devices-list' || 'unit-list' ? 'unit-scroll-h' : ''
          "
        >
          <validation-observer ref="unitAllocationRules">
            <b-row>
              <b-col :md="isDevice ? 12 : 6">
                <validation-provider
                  #default="validationContext"
                  name="field"
                  rules="required"
                >
                  <b-form-group
                    :label="
                      isDevice
                        ? allocationData && allocationData.allocatedToId
                          ? $t('device.DeviceReallocateTo')
                          : $t('device.DeviceallocateTo')
                        : $t('unit.UnitReAllocateTo')
                    "
                    :state="getValidationState(validationContext)"
                  >
                    <treeselect
                      v-model="allocate_to_id"
                      :multiple="false"
                      :options="unitReAllocateTo"
                      :searchable="true"
                      ref="treeselect"
                      :clearable="false"
                      :clear-on-select="true"
                      :allowClearingDisabled="true"
                      @open="handleMenuOpen"
                      @select="handleTreeSelect"
                      @search-change="searchAccount"
                    >
                      <div
                        slot="option-label"
                        class="tree-select-opction"
                        slot-scope="{ node }"
                        :title="node.label"
                      >
                        {{ node.label }}
                      </div>
                    </treeselect>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{
                        validationContext.errors[0]
                          ? handleError(validationContext.errors[0])
                          : ""
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col v-if="!isDevice" md="6">
                <b-form-group :label="$t('unit.UnitOperator')">
                  <v-select
                    id="vue-select"
                    :placeholder="$t('unit.SelectUnitOperator')"
                    v-model="unit_driver_id"
                    :options="unitDriver"
                    :reduce="(option) => option.id"
                    label="title"
                    ref="unitDriver"
                    :disabled="isEnabled == true"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="!isDevice" md="6">
                <b-form-group
                  :label="$t('operators.StartDate')"
                  class="date-mb new-date-picker-25"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Start date"
                    rules="required"
                  >
                    <DateTimePicker
                      v-model="start_date"
                      :placeholder="$t('unit.EnterStartDate')"
                      :isEditable="true"
                      :isMinDate="true"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{
                        validationContext.errors[0]
                          ? handleError(validationContext.errors[0])
                          : ""
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="!isDevice" md="6" class="new-date-picker-25">
                <b-form-group :label="$t('unit.EndDate')" class="date-mb">
                  <DateTimePicker
                    v-model="end_date"
                    :placeholder="$t('unit.EnterEndDate')"
                    :isEditable="true"
                    :isMinDate="true"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="!isDevice" md="6">
                <b-form-group>
                  <b-form-checkbox
                    v-model="isTransferUnitData"
                    class="legal-entity"
                  >
                    {{ $t("unit.IsTransferUnitData") }}
                    <feather-icon
                      icon="InfoIcon"
                      class="text-primary margin-left-8px"
                      v-b-tooltip.hover.top="$t('unit.IsTransferUnitDataInfo')"
                    />
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>

          <div class="d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="
                isDevice
                  ? checkDeviceAllocated(id, $event)
                  : unitReallocation(id, $event, (isAllocated = false))
              "
            >
              {{ isDevice ? $t("device.Allocate") : $t("device.Save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              @click="onCloseAllocation()"
              class="mt-2"
            >
              {{ $t("device.Cancel") }}
            </b-button>
          </div>

          <hr />
          <div class="mt-1">
            <h6 class="text-center mr-b-1">{{ $t("device.history") }}</h6>

            <History
              :isPaginationVisible="false"
              :myHistoryData="allocationHistory"
              :currentPage="currentPage"
              :perPage="perPage"
              :totalDevices="totalUnit"
              :callMyApiPart="showAllocation"
            />
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <b-modal
      id="is-unit-allocated"
      centered
      size="sm"
      modal-class="no-header-modal"
    >
      <p>{{ isUnitAllocated }}</p>
      <div class="d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          v-b-modal.unit-allocate
          @click="unitReallocation(id, $event, (isAllocated = true))"
        >
          {{ $t("unit.Yes") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          @click="onCloseUnitAllocation('is-unit-allocated')"
          class="mt-2"
        >
          {{ $t("unit.No") }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="is-device-allocated-unit"
      centered
      size="sm"
      modal-class="no-header-modal"
    >
      <p>{{ modelText }}</p>
      <div class="d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          v-b-modal.unit-allocate
          @click="deviceReallocation"
        >
          {{ $t("unit.Yes") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          @click="onCloseModel"
          class="mt-2"
        >
          {{ $t("unit.No") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="cannot-reallocate-unit"
      centered
      size="sm"
      modal-class="no-header-modal"
    >
      <p>{{ cannotAllocate }}</p>
      <div class="d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          v-b-modal.unit-allocate
          @click="
            () => {
              this.$bvModal.hide('cannot-reallocate-unit');
            }
          "
        >
          {{ $t("unit.Ok") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import flatPickr from "vue-flatpickr-component";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BBadge,
  BCardText,
  BTab,
  BTabs,
  BFormCheckbox,
  VBTooltip
} from "bootstrap-vue";
import { required } from "@validations";
import AccountService from "@/libs/api/account-service";
import UnitService from "@/libs/api/unit-service";
import DeviceService from "@/libs/api/device-service";
import History from "@/layouts/components/History.vue";
import * as moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Ripple from "vue-ripple-directive";
import constants from "@/utils/constants";
import DateTimePicker from "../DynamicComponents/DateWithTimePicker.vue";
export default {
  name: "AllocationPopUp",
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormFile,
    BImg,
    BButton,
    BCard,
    flatPickr,
    BDropdown,
    BDropdownItem,
    BBadge,
    BCardText,
    History,
    Treeselect,
    BTab,
    BTabs,
    DateTimePicker,
    BFormCheckbox
  },

  props: [
    "allocationData",
    "user",
    "component",
    "onClose",
    "changeInput",
    "unit",
    "disabled",
    "isEnabled",
    "resetForm",
    "isConsumer",
    "currentUnit",
    "isDevice",
    "refreshed",
    "getAccountDetail"
  ],
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      id: null,
      allocate_to_id: null,
      old_allocate_to_id: null,
      unit_driver_id: null,
      showLoading: false,
      unitReAllocateTo: [],
      unitDriver: [],
      required,
      refFormObserver,
      getValidationState,
      start_date: this.formattedDateTime(constants.DATE_TIME_FORMAT),
      end_date: null,
      disabledDate: this.DateTimeConvert(),
      endDateDisabled: null,
      filter_start_date: null,
      filter_end_date: null,
      allocationHistory: [],
      currentPage: 1,
      modelText: "",
      totalUnit: 0,
      perPage: 10,
      minTime: null,
      isFetchingOptions: false,
      isUnitAllocated: "",
      filterAccount: "",
      cannotAllocate: "",
      isTransferUnitData: false
    };
  },
  mounted() {
    this.getChildAccounts();
    this.unitAllocationHistory();
  },
  watch: {
    allocationData(val) {
      this.allocate_to_id = this.isDevice
        ? this.allocationData.allocate_to_id
          ? this.allocationData.allocate_to_id
          : this.allocationData.allocatedToId
        : this.allocationData.allocate_to_id
        ? this.allocationData.allocate_to_id
        : this.allocationData["account.id"];
      this.unit_driver_id = this.allocationData.unit_driver_id
        ? this.allocationData.unit_driver_id
        : this.allocationData["user.id"];

      // this.start_date =
      //   this.start_date && this.start_date > new Date()
      //     ? this.start_date
      //     : moment().format();
      // this.end_date = this.end_date ? moment(this.end_date).format() : null;
    },
    allocate_to_id(val) {
      val &&
        this.changeInput({
          ...this.allocationData,
          allocate_to_id: val,
          ["account.id"]: val
        });
      this.unitDriver = [];
      this.unit_driver_id = null;

      if (val) {
        if (localStorage.getItem("USER_ACCOUNT_ROLE_NAME") != "Operator") {
          this.getAllOperators(val);
        }
      } else {
        !this.isDevice &&
          this.changeInput({
            ...this.allocationData,
            unit_driver_id: null,
            ["user.id"]: null
          });
        this.unit_driver_id = null;
      }
    },
    unit_driver_id(val) {
      if (val) {
        this.changeInput({
          ...this.allocationData,
          unit_driver_id: val
        });
      } else {
        this.changeInput({
          ...this.allocationData,
          unit_driver_id: null,
          ["user.id"]: null
        });
      }
    },
    start_date(val) {
      this.changeInput({
        ...this.allocationData,
        start_date: val
          ? val
          : this.formattedDateTime(constants.DATE_TIME_FORMAT)
      });
      this.endDateDisabled =
        val && moment(val).format() > moment().format()
          ? this.formattedDateTime(constants.DATE_FORMAT)
          : null;

      if (
        !val ||
        moment(val).format() <
          moment()
            .add(1, "days")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format()
      ) {
        this.minTime = moment().format("HH:mm");
      } else {
        this.minTime = moment()
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          })
          .format("HH:mm");
      }
    },
    end_date(val) {
      this.changeInput({
        ...this.allocationData,
        end_date: val
      });
      if (
        !val ||
        moment(val).format() <
          moment()
            .add(1, "days")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format()
      ) {
        this.minTime = moment().format("HH:mm");
      } else {
        this.minTime = moment()
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          })
          .format("HH:mm");
      }
    }
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  beforeDestroy() {
    removeEventListener("scroll", (event) => {});
  },
  methods: {
    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.unitAllocationRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    async isSelectedTab() {
      let requestData = {
        allocate_to_id: this.currentUnit.allocate_to_id
          ? this.currentUnit.allocate_to_id
          : this.currentUnit["account.id"],
        isReallocate: true,
        id: this.currentUnit.id,
        isTransferUnitData: this.isTransferUnitData
      };
      let response = await new UnitService().unitReallocation(requestData);
      if (response && response.data) {
        this.old_allocate_to_id = this.allocationData.allocate_to_id;
        if (response.data.endDateUpdated) {
          this.$bvModal.hide("is-unit-allocated");
          // this.start_date = new Date();
        } else {
          this.$bvModal.hide("unit-allocate");
          this.refreshed();
        }
        this.unitAllocationHistory();
      } else if (response && response.error && response.error.message) {
        this.$bvModal.show("is-unit-allocated");
        this.isUnitAllocated = this.getMessage(response.error.message) + "?";
      }
      this.$bvModal.hide("is-unit-allocated");
      // this.start_date = new Date();
    },
    searchAccount(filterAccount) {
      this.getChildAccounts(filterAccount);
    },
    onCloseAllocation() {
      this.$bvModal.hide("unit-allocate");
    },
    onCloseUnitAllocation() {
      this.$bvModal.hide("is-unit-allocated");
    },
    handleMenuOpen() {
      this.$nextTick(() => {
        const menu = this.$refs.treeselect.getMenu();
        const hasReachedEnd =
          menu.scrollHeight - menu.scrollTop <= menu.clientHeight * 0.25;
        menu.addEventListener("scroll", () => {
          if (hasReachedEnd) this.fetchOptions();
        });
      });
    },

    async fetchOptions() {
      if (this.isFetchingOptions) return;
      this.isFetchingOptions = true;

      let response = await new AccountService().getChildAccounts({
        isChild: true,
        page: this.currentPage,
        page_size: parseInt(this.perPage),
        filterAccount: filterAccount || undefined
      });

      if (response && response.data) {
        this.unitReAllocateTo = response.data;
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.isFetchingOptions = false;
    },

    // minDate(start_date) {
    //   if (start_date == null) {
    //     return this.disabledDate;
    //   } else {
    //     start_date <= new Date() ? "" : this.disabledDate;
    //   }
    // },

    showAllocation() {
      this.unitAllocationHistory();
      this.$bvModal.show("unit-allocate");
    },
    async unitAllocationHistory() {
      if (this.allocationData && this.allocationData.id) {
        let response = this.isDevice
          ? await new DeviceService().deviceAllocationHistory({
              device_id: this.allocationData.id,
              start_date: null,
              end_date: null,
              page: 1,
              page_size: 1
            })
          : await new UnitService().unitAllocationHistory({
              id: this.allocationData.id
                ? this.allocationData.id
                : this.allocationData.unit_id,
              start_date: this.convertDatePerTimezone(this.filter_start_date),
              end_date: this.convertDatePerTimezone(this.filter_end_date),
              page: 1,
              page_size: 1
            });
        if (response && response.data) {
          this.show = false;
          let allocateHostory = [];
          if (
            !this.isDevice &&
            response.data.unitAllocationHistory &&
            response.data.unitAllocationHistory.length
          ) {
            response.data.unitAllocationHistory.map((h) => {
              let history = {
                account: h.account,
                start_date: h.start_date,
                end_date: h.end_date ? h.end_date : "",
                unit_id: h.unit_id,
                isEditable: h.isEditable ? h.isEditable : "",
                isDeleteable: h.isDeleteable ? h.isDeleteable : ""
              };
              allocateHostory.push(history);
            });
          } else if (
            this.isDevice &&
            response.data.deviceAllocationHistory &&
            response.data.deviceAllocationHistory.length
          ) {
            response.data.deviceAllocationHistory.map((h) => {
              let history = {
                account: h.account,
                start_date: this.DateTimeConvert(h.start_date),
                end_date: h.end_date ? this.DateTimeConvert(h.end_date) : ""
              };
              allocateHostory.push(history);
            });
          }

          this.allocationHistory = allocateHostory;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    },
    closeUnitAllocate() {
      this.end_date = null;
      this.isTransferUnitData = false;
    },
    async getChildAccounts(filterAccount) {
      try {
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: filterAccount || undefined,
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });

        if (response && response.data && !this.isDevice) {
          this.getAccountDetail(response.data[0]);
          this.unitReAllocateTo = response.data;
        } else if (response && response.data && this.isDevice) {
          function recursive(data) {
            if (data && data.type.toUpperCase() == "CONSUMER") return true;

            if (data && data.children) {
              for (let i = 0; i < data.children.length; i++) {
                if (recursive(data.children[i])) {
                  // delete data.children[i];
                  data.children[i]["isDisabled"] = true;
                }
              }
            }
            return false;
          }
          recursive(response.data[0]);
          this.unitReAllocateTo = response.data;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        if (this.allocationData && this.allocationData.allocate_to_id) {
          this.allocate_to_id = this.allocationData.allocate_to_id
            ? this.allocationData.allocate_to_id
            : this.allocationData["account.id"];
          this.unit_driver_id = this.allocationData.unit_driver_id
            ? this.allocationData.unit_driver_id
            : this.allocationData["user.id"];
          this.start_date = this.allocationData.start_date
            ? moment(this.allocationData.start_date).format()
            : null;
          this.end_date = this.allocationData.end_date
            ? moment(this.allocationData.end_date).format()
            : null;
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    async getAllOperators(id) {
      try {
        let response = await new UnitService().getOperators({
          getAccountOperator: id
        });
        if (response && response.data) {
          this.unitDriver = response.data.list.map((u) => {
            return {
              id: u.id,
              title: u.name,
              value: u.id
            };
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    onCloseModel() {
      this.$bvModal.hide("is-device-allocated-unit");
    },
    async checkDeviceAllocated() {
      try {
        if (!this.allocate_to_id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unit.PleaseSelectAccount"),
              icon: "EditIcon",
              variant: "danger"
            }
          });
          return;
        }
        if (
          this.allocationData.allocatedToId &&
          this.allocationData.allocate_to_id &&
          this.allocationData.allocatedToId ===
            this.allocationData.allocate_to_id
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unit.alreadyAllocatedSameAccount"),
              icon: "EditIcon",
              variant: "danger"
            }
          });
          return;
        }
        const isDeviceAllocatedToUnit =
          await new UnitService().checkDeviceAllocated({
            device_id: this.allocationData.id
          });
        if (isDeviceAllocatedToUnit && isDeviceAllocatedToUnit.error) {
          this.modelText = isDeviceAllocatedToUnit.error.message;
          this.$bvModal.show("is-device-allocated-unit");
        } else if (
          isDeviceAllocatedToUnit &&
          isDeviceAllocatedToUnit.data &&
          isDeviceAllocatedToUnit.data.success
        ) {
          this.deviceReallocation();
          this.onCloseModel();
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async deviceReallocation(id, e) {
      try {
        const requestData = {
          device_id: this.allocationData.id,
          old_device_owner_id: this.allocationData.allocatedToId,
          account_id: this.allocationData.allocate_to_id
        };
        let response = await new DeviceService().reallocateDevice(requestData);
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("device.AllocatedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.unitAllocationHistory();
          this.$bvModal.hide("unit-allocate");
          this.$bvModal.hide("is-device-allocated-unit");
          this.onClose();
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    async unitReallocation(id, e, isAllocated) {
      await this.validationForm();
      e.preventDefault();
      try {
        if (
          JSON.stringify(this.allocationData) ===
          JSON.stringify(this.currentUnit)
        ) {
          return;
        }

        let requestData = {
          id: this.allocationData.id
            ? this.allocationData.id
            : this.allocationData.unit_id,
          allocate_to_id: this.allocationData.allocate_to_id
            ? this.allocationData.allocate_to_id
            : this.allocationData["account.id"],
          isAllocated,
          isTransferUnitData: this.isTransferUnitData
        };

        if (
          this.allocationData.allocate_to_id != this.currentUnit.allocate_to_id
        ) {
          requestData.allocate_to_id = this.allocationData.allocate_to_id;
          requestData.old_allocate_to_id = this.currentUnit.allocate_to_id
            ? this.currentUnit.allocate_to_id
            : this.currentUnit["account.id"];
        }
        if (
          this.allocationData.unit_driver_id != this.currentUnit.unit_driver_id
        ) {
          requestData.unit_driver_id = this.allocationData.unit_driver_id
            ? this.allocationData.unit_driver_id
            : null;
          requestData.old_unit_driver_id = this.currentUnit.unit_driver_id
            ? this.currentUnit.unit_driver_id
            : null;
        }

        if (this.start_date != this.currentUnit.start_date) {
          requestData.start_date = this.allocationData.start_date
            ? this.convertDatePerTimezone(this.start_date)
            : null;
          requestData.old_start_date = new Date(this.currentUnit.start_date);
        }
        if (this.allocationData.end_date != this.currentUnit.end_date) {
          requestData.end_date = this.allocationData.end_date
            ? this.convertDatePerTimezone(this.end_date)
            : null;
          requestData.old_end_date = new Date(this.currentUnit.end_date);
        }
        if (!requestData.end_date) {
          requestData.end_date = undefined;
        }
        requestData.isUpdateDate =
          this.allocationData.start_date == this.currentUnit.start_date
            ? true
            : false;
        if (requestData.start_date && requestData.end_date) {
          const startTime = moment(requestData.start_date);
          const endTime = moment(requestData.end_date);
          if (startTime.isSameOrAfter(endTime)) {
            throw {
              message: this.$t("unit.EndTimeGreaterThanStartTime")
            };
          }
        }
        let response = await new UnitService().unitReallocation(requestData);
        if (response && response.data && response.data.success == true) {
          this.$bvModal.hide("unit-allocate");
          this.$bvModal.hide("is-unit-allocated");
          this.refreshed(response.data.unit_id);
          this.old_allocate_to_id = this.allocationData.allocate_to_id;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unit.ReallocatedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.unitAllocationHistory();
        } else if (
          response &&
          response.error &&
          response.error.code &&
          response.error.message
        ) {
          if (
            response.error.code === "POLY_ERR_UNIT_NOT_ABLE_TO_ALLOCATE_ACCOUNT"
          ) {
            this.$bvModal.show("is-unit-allocated");
            this.isUnitAllocated =
              this.getMessage(response.error.message) + "?";
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.message,
                icon: "InfoIcon",
                variant: "danger"
              }
            });
          }
        } else if (
          response &&
          response.data &&
          response.data.success == false
        ) {
          this.cannotAllocate = this.$t("unit.CannotAllocateUnit", {
            start_date: this.DateTimeConvert(response.data.start_date),
            end_date: this.DateTimeConvert(response.data.end_date)
          });
          this.$bvModal.show("cannot-reallocate-unit");
        }
      } catch (err) {
        localStorage.setItem("error", JSON.stringify(err));
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    getMessage(e) {
      const delimiter = "Correlation ID: ";
      const parts = e.split(delimiter);
      if (parts.length) {
        return parts[0];
      } else {
        return e + "?";
      }
    },
    handleTreeSelect(e) {
      this.changeInput({
        ...this.allocationData,
        unit_driver_id: null,
        ["user.id"]: null
      });
    }
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.flat-picker-date {
  display: flex;
  justify-content: center;
  align-items: center;
  .form-control {
    border-radius: 0.357rem 0rem 0rem 0.357rem;
  }
}
.flat-picker-icon {
  border-right: 1px solid var(--gray4);
  border-top: 1px solid var(--gray4);
  border-bottom: 1px solid var(--gray4);
  border-radius: 0rem 0.357rem 0.357rem 0rem;
  padding: 0.438rem 1rem;
}
#unit-allocate .modal-dialog.modal-lg {
  max-width: 685px;
}
// .unit-scroll-a{
//    max-height: calc(100vh - 330px) !important;
//    overflow-y: auto;
//    overflow-x: hidden;

// }
.modal-h-s {
  max-height: 99vh;
}
</style>
<style lang="scss">
.unit-scroll-h {
  .scroll-h-table-create {
    height: auto !important;
  }
}
</style>
<style lang="scss">
.new-date-picker-25 {
  .daterangepicker.ltr.show-calendar.single.openscenter.linked {
    left: 0 !important;
    transform: translate(0%) !important;
  }
}
</style>
<style lang="scss" scoped>
.margin-left-8px {
  margin-left: 8px;
}
</style>

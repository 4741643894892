import APIService from "./api";

/** This class will include all services defined in rule-service */
export default class GeoService extends APIService {
  service = "geo-service";
  async findAddressFromCordinates(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "findAddressFromCordinates" },
      data,
      options
    );
  }
}

<template>
  <div class="alerts-search-comp">
    <b-card class="alert-search position-relative main-card-padding">
      <div class="m-2-br-alert-bottm padding-10">
        <div
          :class="
            $route.name !== 'notification-create'
              ? 'adduser-btn-row'
              : 'adduser-btn-row'
          "
        >
          <h4 class="card-title" v-if="$route.name !== 'notification-create'">
            {{ $t("alert.Alerts") }}
          </h4>
          <h4 class="card-title" v-if="$route.name === 'notification-create'">
            {{ $t("Notification.SearchInAlerts") }}
            {{ searchAlert ? `“${searchAlert}”` : "" }}
          </h4>
          <div class="filter-btn-outer-new d-flex">
            <div
              class="search-outer-alert desktop-hidden-account padding-btm-0 mb-1 mt-1"
              v-if="
                !groupName &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ALERTS_SUB_ACCOUNT,
                  subject: constants.PERMISSIONS_MODEL.ALERTS
                }) &&
                $route.name !== 'notification-create'
              "
            >
              <AccountSearch
                all="true"
                class="unit-search-v2"
                v-model="selectedAccount"
              />
            </div>
            <b-form-checkbox
              v-if="!groupName && $route.name !== 'notification-create'"
              v-model="isAllowChild"
              v-b-tooltip.hover.v-primary
              :title="
                isAllowChild
                  ? $t('tooTip.HideInheritedItems')
                  : $t('tooTip.ShowInheritedItems')
              "
              switch
              class="desktop-hidden"
            ></b-form-checkbox>
            <b-button
              v-b-tooltip.hover.v-primary
              :title="$t('alert.AddAlert')"
              variant="primary"
              class="btn-icon desktop-hidden mr-1 max-h-alert-btn"
              @click="addAlert"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.ALERTS
                }) && !isGroupEntity
              "
            >
              <feather-icon icon="PlusIcon" size="12" />
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              class="desktop-hidden max-h-alert-btn"
              :class="
                $route.name == 'notification-create' ? 'mobile-hide-r' : ''
              "
              @click="showFiltermenu = !showFiltermenu"
            >
              <feather-icon icon="FilterIcon" size="15" />
            </b-button>
          </div>
          <div class="d-flex flex-wrap filter-card" v-if="showFiltermenu">
            <div
              class="alert-per-page"
              v-if="$route.name !== 'notification-create'"
            >
              <div class="addXIcon">
                <feather-icon
                  class="sizeIcon"
                  @click="showFiltermenu = !showFiltermenu"
                  icon="XIcon"
                  size="15"
                />
              </div>
              <label class="search-label mt-1">{{
                $t("Notification.Entries")
              }}</label>
              <v-select
                :clearable="false"
                id="vue-select"
                class="per-page-selector d-inline-block fix-83"
                v-model="perPage"
                :options="option"
              />
            </div>

            <search
              v-if="$route.name !== 'notification-create'"
              style="float: left"
              class="search-comp no-btm-0"
              v-model="searchFiled"
              :searchColumn="searchColumn"
              :operator="operator"
            ></search>

            <div
              class="d-flex justify-content-end mobile-hidden align-items-center"
            >
              <div
                class="search-outer-alert padding-btm-0 mb-1 mt-1"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.ALERTS_SUB_ACCOUNT,
                    subject: constants.PERMISSIONS_MODEL.ALERTS
                  }) &&
                  !groupName &&
                  $route.name !== 'notification-create'
                "
              >
                <AccountSearch
                  all="true"
                  class="unit-search-v2"
                  v-model="selectedAccount"
                />
              </div>
              <b-form-checkbox
                v-if="!groupName && $route.name !== 'notification-create'"
                v-model="isAllowChild"
                v-b-tooltip.hover="{
                  title: isAllowChild
                    ? $t('tooTip.HideInheritedItems')
                    : $t('tooTip.ShowInheritedItems'),
                  customClass: 'device-tooltip-class '
                }"
                switch
                class="mx-h"
              ></b-form-checkbox>
              <b-button
                v-b-tooltip.hover.v-primary
                :title="$t('alert.AddAlert')"
                variant="primary"
                class="btn-icon"
                @click="addAlert"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.ADD,
                    subject: constants.PERMISSIONS_MODEL.ALERTS
                  }) && !isGroupEntity
                "
              >
                <feather-icon icon="PlusIcon" size="12" />
                <span class="mobile-hidden">{{ $t("alert.AddAlert") }}</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <AlertList
        :searchFiled="searchFiled"
        :perPage="perPage"
        :groupName="groupName"
        :isGroupEntity="isGroupEntity"
        :getSelectedAlert="getSelectedAlert"
        :alertId="alertId"
        :isAllowChild="isAllowChild"
        :selectedAccount="selectedAccount"
      ></AlertList>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAlert,
  BButton,
  VBTooltip,
  BFormCheckbox
} from "bootstrap-vue";
import AlertList from "@/views/Alert/AlertList.vue";
import constants from "@/utils/constants";
import Search from "../Common/search.vue";
import vSelect from "vue-select";
import AccountSearch from "../Unit/Unit-V2/AccountSearch.vue";
export default {
  components: {
    BCard,
    BAlert,
    BButton,
    VBTooltip,
    AlertList,
    Search,
    vSelect,
    BFormCheckbox,
    AccountSearch
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      searchColumn: ["name"],
      searchFiled: {},
      operator: "ilike",
      perPage: this.nearestPage(
        ["10", "20", "30"],
        parseInt(window.innerHeight / 71)
      ),
      option: ["10", "20", "30"],
      showFiltermenu: false,
      isAllowChild: true,
      selectedAccount:
        this.$route.query.account_id || localStorage.getItem("USER_ACCOUNT_ID")
    };
  },

  props: {
    groupName: {
      type: String,
      default: () => null
    },

    isGroupEntity: {
      type: Boolean,
      default: () => false
    },
    searchAlert: {
      type: String
    },
    alertId: {
      type: String
    },
    selectedAlert: {
      type: Function
    },
    selectedAlertId: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    searchAlert(val) {
      this.searchFiled = { field: "name", operator: "ilike", value: val };
    },
    selectedAlertId(val) {
      this.searchFiled = { field: "name", operator: "ilike", value: val.name };
    },
    selectedAccount() {
      if (this.showFiltermenu) {
        this.onWindowResize();
      }
    }
  },
  mounted() {
    const { perPage } = this.$route.query;

    if (perPage) {
      this.perPage = parseInt(perPage);
    }
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },
    addAlert() {
      this.$router.push({ name: "alert-create" });
    },
    getSelectedAlert(e) {
      this.selectedAlert(e);
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.mx-h {
  .custom-control-label::before {
    max-height: 1.55rem !important;
  }
  .custom-control-label::after {
    max-height: 1.55rem !important;
  }
}
</style>
<style lang="scss" scoped>
.alert-search {
  .alert-per-page {
    margin-left: auto;
    margin-right: 15px;
  }
  .search-filter {
    margin-left: 0;
    margin-right: 8px;
  }
  .operator-table-width {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}
.btn {
  border: 0px solid transparent !important;
}
.mobile-hide-r {
  display: none;
}
.search-outer-alert {
  max-width: 235px !important;
  margin-top: 2rem !important;
  margin-right: 1rem !important;
}
.max-h-alert-btn {
  max-height: 40px;
  margin-top: 2rem;
}
@media screen and (min-width: 820px) {
  .desktop-hidden-account {
    display: none;
  }
}
</style>

<template>
  <b-media no-body v-if="!(v === null || v === undefined)">
    <b-media-aside class="mr-75 marginLeftImg">
      <speed-icon />
    </b-media-aside>
    <h6 class="align-self-center my-auto">
      <b> {{ $t("VehicleSpeed") }}</b> :
      <span class="speed-cls"> {{ v }} km/h</span>
    </h6>
  </b-media>
</template>

<script>
import { BMedia, BMediaBody, BImg, BMediaAside, BAvatar } from "bootstrap-vue";
import SpeedIcon from "@/assets/images/icons/unit-icons/speed.svg";
export default {
  name: "speed",
  components: { BMedia, BMediaBody, BImg, BMediaAside, BAvatar, SpeedIcon },
  props: ["v"],
  methods: {},

  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.media {
  align-items: center !important;
  padding-top: 10px;
}
.speed-cls {
  font-weight: bold;
  color: #7367f0;
}
</style>

<template>
  <b-media no-body v-if="!(level === null || level === undefined)">
    <b-media-aside class="mr-75 marginLeftImg">
      <fuel-level />
      <!-- <b-avatar square size="20" :src="speedImage" variant="light-primary" /> -->
    </b-media-aside>
    <h6
      class="align-self-center my-auto"
      v-b-tooltip.hover.v-primary
      :title="name"
    >
      <b> Fuel Level</b> :
      <span class="get-fuel-level">
        {{ getFuelLevel() }}
      </span>
    </h6>
  </b-media>
</template>

<script>
import {
  BMedia,
  BMediaBody,
  BImg,
  BMediaAside,
  BAvatar,
  VBTooltip
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FuelLevel from "@/assets/images/icons/unit-icons/fuel-level.svg";
export default {
  name: "fuel",
  components: {
    BMedia,
    BMediaBody,
    BImg,
    BMediaAside,
    BAvatar,
    VBTooltip,
    FuelLevel
  },
  props: ["level", "capacity", "unit", "name"],
  methods: {
    getFuelLevel() {
      return ` ${this.level} ${this.unit} ${
        this.capacity ? `(Total capacity ${this.capacity} ${this.unit})` : ""
      }`;
    }
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },

  data() {
    return {};
  }
};
</script>

<style lang="scss">
.media {
  align-items: center !important;
  padding-top: 10px;
}
.get-fuel-level {
  font-weight: bold;
  color: #7367f0;
}
</style>
